import "whatwg-fetch";
import { ApiConfig } from "../constants/ApiConfig";
import {
  hydrateJobOffers,
  hydrateTechnologies,
  buildBlogApiUrl,
  buildBlogDetailsObj,
  buildRecruiterDetailsObj,
} from "./API.helpers";

export function fetchJobOffers(actions) {
  return getNumberRecords("offers")
    .then((recordsNumber) =>
      fetch(`${ApiConfig.BACKEND_URL}/wp-json/wp/v2/offers?_embed&per_page=${recordsNumber}`, {
        method: "GET",
      })
    )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((jobOffers) => actions.setJobOffers(hydrateJobOffers(jobOffers)));
}

export function fetchTechnologies(actions) {
  return getNumberRecords("technologies")
    .then((recordsNumber) =>
      fetch(`${ApiConfig.BACKEND_URL}/wp-json/wp/v2/technologies?per_page=${recordsNumber}`, {
        method: "GET",
      })
    )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((technologies) => actions.setTechnologies(hydrateTechnologies(technologies)));
}

function getNumberRecords(endpoint) {
  return fetch(`${ApiConfig.BACKEND_URL}/wp-json/wp/v2/${endpoint}`, {
    method: "HEAD",
  }).then((response) => {
    if (response.ok) {
      return response.headers.get("X-WP-Total");
    }
  });
}

export function refreshAgreement(userHash) {
  return fetch(`${ApiConfig.BACKEND_URL}/wp-json/pgscareer/v1/refresh-agreement`, {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
    body: JSON.stringify({
      hash: userHash,
    }),
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((response) =>
      response
        ? {
            success: true,
            user: response,
          }
        : {
            success: false,
          }
    );
}

export function fetchBlogDetails(url) {
  let { apiUrl, lang } = buildBlogApiUrl(url);

  return fetch(apiUrl, {
    method: "GET",
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((response) => {
      return buildBlogDetailsObj(response, lang);
    })
    .catch(() => {
      return {};
    });
}

export function fetchRecruiterDetails(id) {
  return fetch(`${ApiConfig.BACKEND_URL}/wp-json/wp/v2/recruiter/${id}?_embed`, {
    method: "GET",
  })
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .then((response) => {
      return buildRecruiterDetailsObj(response);
    })
    .catch(() => {
      return {};
    });
}

export function fetchSlidesContent(id) {
  return fetch(
    `${ApiConfig.BACKEND_URL}/wp-json/wp/v2/slider?_embed&include=${id}&orderby=include`,
    {
      method: "GET",
    }
  )
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
    })
    .catch(() => {
      return {};
    });
}
