import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AppActions from "../../../actions/index";
import { sendGa, sendGa4 } from "../../../common/utils";
import { gtagReportConversion } from "../../../common/utils";

import "./_activate-button.scss";

class ActivateButton extends Component {
  button = React.createRef();
  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.cvModalOpen && this.props.cvModalOpen === false) {
      this.button.current.focus();
      this.button.current.scrollIntoView();
    }
  }

  onClick(event) {
    event.preventDefault();
    window.fbq("trackCustom", "Soft_Aplikacja");

    if (this.props.action) {
      this.props.action();
    } else {
      window.scrollTo(0, 0);
      this.props.actions.openCvModal();
    }

    this.props.gaTracking && gtagReportConversion();
    this.props.gaTracking && sendGa(this.props.gaTracking.category, this.props.gaTracking.label);
    this.props.ga4Tracking && sendGa4(this.props.ga4Tracking.eventName);

    if (window.lintrk) {
      window.lintrk("track", { conversion_id: 12120857 });
    }
  }

  render() {
    if (this.props.type === "link") {
      return (
        <a
          href="#"
          aria-label={this.props.ariaLabel}
          onClick={this.onClick.bind(this)}
          className={`UploadButton ${this.props.additionalClass}`}
          tabIndex={this.props.cvModalOpen ? "-1" : "0"}
        >
          <span>{this.props.text}</span>
        </a>
      );
    }

    return (
      <button
        ref={this.button}
        aria-label={this.props.ariaLabel}
        onClick={this.onClick.bind(this)}
        className={`UploadButton ${this.props.additionalClass}`}
        tabIndex={this.props.cvModalOpen ? "-1" : "0"}
      >
        <span>{this.props.text}</span>
      </button>
    );
  }
}

function mapStateToProps(state) {
  return {
    cvModalOpen: state.cvModal.cvModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivateButton);
