import * as ACTION_TYPES from "../constants/ActionTypes";

const initialState = {
  isVisible: true,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.CLOSE_COOKIE_BANNER:
      return {
        isVisible: false,
      };
    default:
      return state;
  }
}
