import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import SendCVSuccess from "../components/SendCVSuccessPage/SendCVSuccess";
import DocumentTitle from "../components/common/DocumentTitle/DocumentTitle";
import { withHistoryLocation, withNavigate } from "../components/helpers";

class SendCvSuccessContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsletter: false,
      tracking: {
        isActive: false,
      },
    };
  }

  componentDidMount() {
    if (this.props.location.state) {
      window.scrollTo(0, 0);

      this.setState({
        newsletter: this.props.location.state.newsletter,
        tracking: this.props.location.state.tracking,
      });
      // Event snippet for Wysyłanie CV conversion page
      const scriptGtag = document.createElement("script");
      scriptGtag.innerHTML =
        "gtag('event', 'conversion', {'send_to': 'AW-415721186/ZWxeCNnk9P4CEOLNncYB'});";
      document.head.appendChild(scriptGtag);
    } else {
      this.props.navigate("/");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.state !== this.props.location.state) {
      window.scrollTo(0, 0);

      this.setState({
        newsletter: this.props.location.state.newsletter,
        tracking: this.props.location.state.tracking,
      });
    }
  }

  render() {
    const { newsletter, tracking } = this.state;

    return (
      <Fragment>
        <SendCVSuccess
          newsletter={newsletter}
          tracking={tracking}
          cvModalOpen={this.props.cvModalOpen}
        />
        <DocumentTitle title="Thank You" />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    cvModalOpen: state.cvModal.cvModalOpen,
  };
}
export default connect(
  mapStateToProps,
  null
)(withNavigate(withHistoryLocation(SendCvSuccessContainer)));
