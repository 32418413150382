import * as ACTION_TYPES from "../constants/ActionTypes";

const initialState = {
  technologies: [],
  selectedCategory: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_TECHNOLOGIES:
      return {
        ...state,
        technologies: action.technologies,
      };
    case ACTION_TYPES.SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    default:
      return state;
  }
}
