import React from "react";

import "./_insight-popup.scss";

const InsightPopup = (props) => (
  <div className={`InsightPopup ${props.additionalClass ? props.additionalClass : ""}`}>
    {props.imgSrc && (
      <div className="InsightPopup-image" style={{ backgroundImage: `url(${props.imgSrc})` }}></div>
    )}
    <button
      className="InsightPopup-close"
      onClick={props.onButtonClick}
      tabIndex={props.cvModalOpen ? "-1" : "0"}
    ></button>
    <div className="InsightPopup-copyWrapper">
      <h4 className="InsightPopup-title">{props.title}</h4>
      <a
        className="InsightPopup-button"
        href={props.link.url}
        target="_blank"
        onClick={props.onButtonClick}
        tabIndex={props.cvModalOpen ? "-1" : "0"}
        rel="noreferrer"
      >
        {props.link.text}
      </a>
    </div>
  </div>
);

export default InsightPopup;
