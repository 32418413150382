import React from "react";

import "./_page-header.scss";
import xebiaLogo from "../../../assets/img/logo.svg";

function PageHeader({ cvModalOpen }) {
  return (
    <div className="pageHeaderWrapper">
      <div className="pageHeader">
        <a
          className="pageHeader__imgWrapper"
          href="https://xebia.com"
          tabIndex={cvModalOpen ? "-1" : "0"}
        >
          <img className="pageHeader__img" src={xebiaLogo} alt="Xebia main page" />
        </a>
        <h1 className="pageHeader__title">Job offers</h1>
      </div>
    </div>
  );
}

export default PageHeader;
