import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./_consent-checkbox.scss";

const ConsentCheckbox = (props) => {
  return (
    <div className="ConsentCheckbox">
      <span
        className={
          props.fieldIsRequired ? "ConsentCheckbox-span-isRequired" : "ConsentCheckbox-span"
        }
      >
        <input
          className="ConsentCheckbox-checkbox"
          type="checkbox"
          id={props.input.id}
          name={props.input.name}
          checked={props.input.checked}
          required={props.textExpander.isRequired}
          readOnly={true}
          onChange={() => {
            props.label.handleClick();
          }}
        />
        <label
          htmlFor={props.label.for}
          className={classNames({
            "ConsentCheckbox-label": props.label.consent,
            "ConsentCheckbox-label--checked": props.label.checked,
          })}
          onClick={(event) => {
            if (event.target.tagName === "A") {
              return;
            }

            event.preventDefault();
            props.label.handleClick();
          }}
        >
          <div
            className="ConsentCheckbox-text"
            dangerouslySetInnerHTML={{ __html: props.textExpander.consentText }}
          />
        </label>
      </span>
    </div>
  );
};

ConsentCheckbox.propTypes = {
  label: PropTypes.shape({
    for: PropTypes.string.isRequired,
    consent: PropTypes.bool.isRequired,
    checked: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
  }),
  input: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
  }),
  textExpander: PropTypes.shape({
    isRequired: PropTypes.bool.isRequired,
    consentText: PropTypes.string.isRequired,
  }),
};

export default ConsentCheckbox;
