import React, { Component } from "react";
import "./_job-offers-main-filter.scss";
import Select from "react-select";

class JobOffersMainFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value:
        props.initialValue.length > 0
          ? {
              value: props.initialValue[0],
              label: props.initialValue[0],
            }
          : null,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      value:
        nextProps.initialValue.length > 0
          ? {
              value: nextProps.initialValue[0],
              label: nextProps.initialValue[0],
            }
          : null,
    });
  }

  handleChange(selectedItem) {
    this.props.onSelect(
      this.props.field,
      selectedItem.value,
      this.state.value ? this.state.value.value : null
    );
    this.setState({
      value: {
        value: selectedItem.value,
        label: selectedItem.value,
      },
    });
  }

  render() {
    const { field, values } = this.props;
    const options = values.map((value) => ({
      value,
      label: value,
    }));

    return (
      <div className="JobOffersMainFilter">
        <label className="JobOffersMainFilter-label" htmlFor={"JobOffersMainFilter-" + field}>
          {field === "city" ? "Location" : "Technology"}
        </label>
        <Select
          maxMenuHeight={300}
          menuPosition="fixed"
          inputId={"JobOffersMainFilter-" + field}
          options={options}
          classNamePrefix="Select"
          components={{
            IndicatorSeparator: () => null,
            Placeholder: (props) => {
              return (
                <React.Fragment>
                  <span id={props.innerProps.id} className="JobOffersMainFilter-extraInfo">
                    {`Page result will be automatically filtered according to selected ${field}`}
                  </span>
                  <div className="Select__placeholder" aria-hidden={true}>
                    Choose
                  </div>
                </React.Fragment>
              );
            },
          }}
          onChange={this.handleChange.bind(this)}
          value={this.state.value}
          isClearable={false}
          isSearchable={false}
          tabIndex={this.props.cvModalOpen ? "-1" : "0"}
          focusInputOnMenuOpen={false}
          inputProps={{ readOnly: true }}
        />
      </div>
    );
  }
}

export default JobOffersMainFilter;
