import React, { Component } from "react";

import Icon from "../Icon/Icon";

import "./_social-media.scss";

class SocialMedia extends Component {
  render() {
    const iconsColor = this.props.color ? this.props.color : "#000000";
    const size = this.props.size ? this.props.size : 15;
    return (
      <div
        className={`SocialMedia ${this.props.additionalClass ? this.props.additionalClass : ""}`}
      >
        <ul className="SocialMedia-list">
          <li className="SocialMedia-item">
            <a
              href="https://www.facebook.com/xebiapoland"
              target="blank"
              className="SocialMedia-logo"
              title="Visit Xebia at Facebook (opens in a new window)"
              tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
            >
              <Icon brand="Facebook" color={iconsColor} width={size} height={size} />
            </a>
          </li>
          <li className="SocialMedia-item">
            <a
              href="https://www.linkedin.com/company/xebia-poland"
              target="blank"
              className="SocialMedia-logo"
              title="Visit Xebia at Linkedin (opens in a new window)"
              tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
            >
              <Icon brand="LinkedIn" color={iconsColor} width={size} height={size} />
            </a>
          </li>
          <li className="SocialMedia-item">
            <a
              href="https://www.instagram.com/xebiapoland/"
              target="blank"
              className="SocialMedia-logo"
              title="Visit Xebia at Instagram (opens in a new window)"
              tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
            >
              <Icon brand="Instagram" color={iconsColor} width={size} height={size} />
            </a>
          </li>
          <li className="SocialMedia-item">
            <a
              href="https://twitter.com/xebia"
              target="blank"
              className="SocialMedia-logo"
              title="Visit Xebia at Twitter (opens in a new window)"
              tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
            >
              <Icon brand="Twitter" color={iconsColor} width={size} height={size} />
            </a>
          </li>
          <li className="SocialMedia-item">
            <a
              href="https://www.youtube.com/@XebiaPoland"
              target="blank"
              className="SocialMedia-logo"
              title="Visit Xebia at Youtube (opens in a new window)"
              tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
            >
              <Icon brand="YouTube" color={iconsColor} width={size} height={size} />
            </a>
          </li>
          <li className="SocialMedia-item">
            <a
              href="https://www.behance.net/pgssoftware"
              target="blank"
              className="SocialMedia-logo"
              title="Visit Xebia at Behance (opens in a new window)"
              tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
            >
              <Icon brand="Behance" color={iconsColor} width={size} height={size} />
            </a>
          </li>
          <li className="SocialMedia-item">
            <a
              href="https://dribbble.com/xebiapoland"
              target="blank"
              className="SocialMedia-logo"
              title="Visit Xebia at Dribbble (opens in a new window)"
              tabIndex={this.props.cookieBannerVisible || this.props.cvModalOpen ? "-1" : "0"}
            >
              <Icon brand="Dribbble" color={iconsColor} width={size} height={size} />
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default SocialMedia;
