export const OG_DESCRIPTION =
  "Interesting projects with the world’s leading brands, great atmosphere... and more!" +
  "Check out our job offers, send us your resume, and join #Xebia.";
export const BLOG_POST_BUTTONS = {
  en: "Read more",
  pl: "Czytaj więcej",
};

export const MICROFORMATS = {
  XEBIA: {
    NAME: "Xebia sp. z o.o.",
    EMAIL: "kariera@pgs-soft.com",
    TELEPHONE: "+ 48 71 798 26 92",
    ADDRESS_LOCALITY: "Wroclaw, Poland",
    POSTAL_CODE: "50-086",
    STREET_ADDRESS: "ul. Sucha 3",
    INDUSTRY: "Computer Software",
  },
  JOB_OFFERS: {
    NAME: "XEBIA job offers",
  },
};

export const BLUR_500_X_500_B2B_MARKETING =
  "|OFi=KMxS$8^?v%hIo%g.8~qx]WBRjWBNGR*%gt7oJxZkBofV@Rjofxue.M|RjofbbWBnin%WBaeR*NGWBWBRjjEV@f6aeNGWqs:j@WBRPjubHWBNGWVozjZRjWBjZayRjIURjxut7Rjj[oLRjRjM{WBt7jZWBofofV@WB";
export const LINKEDIN_IMG_BLUR =
  "|XL;22V[.TSOv#s.E1jZR*Nbae%gofkWkCWBayjZ%#kCVEs:IUWBxuWBt7tRaeozayNGWBt7kCoft7ofROWBkWWVsooeRjxujtR*aykBayt8ofWAxaayRjjZs:ofRjayofxaj]NGayt7jua}kCR*xZaeR*fks:j[jZayWC";
