import React, { useRef, useState } from "react";
import { Modal } from "../../../Modal/Modal";
import "./_get-to-know-us.scss";
import getToKnowUsImage from "../../../../assets/img/get-to-know-us.svg";
import { ModalBody } from "../../../Modal/ModalBody/ModalBody";

export default function GetToKnowUs() {
  const [modalOpen, setModalOpen] = useState(false);
  const button = useRef(null);
  const onClose = () => {
    setModalOpen(false);
    button.current.focus();
  };
  const modalBody = (
    <Modal>
      <ModalBody closeModalFn={onClose}>
        <iframe
          className="GetToKnowUs-iframe"
          src="https://www.youtube.com/embed/EWlerbhv5rY"
          title="Watch a short movie about us in Xebia"
          frameBorder="0"
          allow="accelerometer; autoplay=0; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </ModalBody>
    </Modal>
  );

  return (
    <section className="ParagraphText">
      <div className="ParagraphText-wrapper GetToKnowUs-paragraph">
        {modalOpen && modalBody}
        <button ref={button} onClick={() => setModalOpen(true)} className="GetToKnowUs-link">
          <img
            className="GetToKnowUs-image"
            src={getToKnowUsImage}
            alt="See this movie to get know us"
          />
          <span className="GetToKnowUs-text" aria-hidden={true}>
            Get to know us
          </span>
        </button>
      </div>
    </section>
  );
}
