import React from "react";
import "./_open-main-carousel.scss";

function OpenMainCarousel({ bgcImage, handleChange }) {
  return (
    <section className="OpenMainCarousel">
      <div className="OpenMainCarousel-content">
        <img className="OpenMainCarousel-bgcImage" src={bgcImage} alt="background image" />
        <div className="OpenMainCarousel-ctaSection">
          <h2 className="OpenMainCarousel-title">Xebia – Your Story</h2>
          <button className="OpenMainCarousel-button" onClick={handleChange}>
            More
          </button>
        </div>
      </div>
    </section>
  );
}

export default OpenMainCarousel;
