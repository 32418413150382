import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import ThreeImages from "../../common/ThreeImages/ThreeImages";

import "./_show-job-offers.scss";

const ShowJobOffers = (props) => (
  <section className="ShowJobOffers">
    <ThreeImages firstImg={props.firstImg} secondImg={props.secondImg} />
    <div className="ShowJobOffers-content">
      <h1 className="ShowJobOffers-header">
        See our job opportunities and join the <span className="is-orange">#PGSTeam</span>
      </h1>
      <Link className="Button" to="/" tabIndex={props.cvModalOpen ? "-1" : "0"}>
        Check our job offers
      </Link>
    </div>
  </section>
);

const propTypes = {
  firstImg: PropTypes.object,
  secondImg: PropTypes.object,
  cvModalOpen: PropTypes.bool,
};

ShowJobOffers.propTypes = propTypes;

export default ShowJobOffers;
