export const TRACKING = {
  linkedin: {
    partnerId: "294601",
  },
};

export const GA_EVENTS = {
  offerCvButton: {
    category: "kariera_oferty_pracy",
    label: "button_wyslijcv",
  },
  navCvButton: {
    category: "kariera_menu",
    label: "button_wyslijcv",
  },
};

export const GA4_EVENTS = {
  offerCvButton: {
    name: "Soft_Aplikacja",
    params: {},
  },
};
