import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as AppActions from "../actions/index";
import PageNotFound from "../components/common/PageNotFound/PageNotFound";
import DocumentTitle from "../components/common/DocumentTitle/DocumentTitle";
import { withHistoryLocation } from "../components/helpers";

class PageNotFoundContainer extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
  }

  componentDidMount() {
    const errorMessage =
      this.props.location.state && this.props.location.state.error
        ? this.props.location.state.error
        : "Page not found";
    this.props.actions.setErrorMessage(errorMessage);
  }

  componentWillUnmount() {
    this.props.actions.resetErrorMessage();
  }

  render() {
    return (
      <Fragment>
        <PageNotFound title={this.props.error} btnText="Check our job offers" />
        <DocumentTitle title="404 Page Not Found" />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    error: state.error.message,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withHistoryLocation(PageNotFoundContainer));
