import * as ACTION_TYPES from "../constants/ActionTypes";

const initialState = {
  cvPromptVisible: false,
  isOffers: false,
  activePage: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SHOW_CV_PROMPT:
      return {
        ...state,
        cvPromptVisible: true,
      };
    case ACTION_TYPES.HIDE_CV_PROMPT:
      return {
        ...state,
        cvPromptVisible: false,
      };
    case ACTION_TYPES.IS_OFFERS:
      return {
        ...state,
        isOffers: true,
      };
    case ACTION_TYPES.IS_NOT_OFFERS:
      return {
        ...state,
        isOffers: false,
      };
    case ACTION_TYPES.SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.pageName,
      };
    default:
      return state;
  }
}
