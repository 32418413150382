import { useEffect } from "react";

const DocumentTitle = ({ title }) => {
  useEffect(() => {
    const prevTitle = document.title;
    document.title = title + " | Xebia Poland";

    return () => {
      document.title = prevTitle + " | Xebia Poland";
    };
  }, []);

  return null;
};

export default DocumentTitle;
