import React from "react";

import InsightPopup from "../InsightPopup/InsightPopup";
import "./_popups.scss";

const Popups = (props) => (
  <div className="Popups">
    {props.blogPopup.show && (
      <div className="Popups-item">
        <InsightPopup
          title={props.blogPopup.data.details.title}
          imgSrc={props.blogPopup.data.details.imgSrc}
          link={{
            text: props.blogPopup.data.details.link.text,
            url: props.blogPopup.data.details.link.url,
          }}
          onButtonClick={props.blogPopup.hide}
          cvModalOpen={props.cvModalOpen}
        />
      </div>
    )}
    {props.pdfPopup.show && (
      <div className="Popups-item">
        <InsightPopup
          title={props.pdfPopup.data.text}
          link={{
            text: props.pdfPopup.data.buttonText,
            url: props.pdfPopup.data.src,
          }}
          onButtonClick={props.pdfPopup.hide}
          additionalClass="InsightPopup--centered"
          cvModalOpen={props.cvModalOpen}
        />
      </div>
    )}
  </div>
);

export default Popups;
