import React, { Component, memo } from "react";
import { Helmet } from "react-helmet-async";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as AppActions from "../actions/index";
import { fetchJobOffers, fetchBlogDetails } from "./API";

import Popups from "../components/OffersPage/Popups/Popups";
import JobMicroformats from "../components/common/Microformats/JobMicroformats";

import * as CONSTANTS from "../constants/Constants";
import { GA_EVENTS, GA4_EVENTS } from "../constants/Tracking";
import JobOfferRedesigned from "../components/OffersPage/JobOfferRedesigned/JobOfferRedesigned";
import Spinner from "../components/common/Spinner/Spinner";
import { fetchRecruiterDetails } from "../containers/API.js";
import { withNavigate, withURLParams } from "../components/helpers";

class Offer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBlogPopup: false,
      showPdfPopup: false,
      recruiterDetails: null,
      isDownloadingRecruiterStarted: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    const offerIdentifier = this.props.params.seoSlug;
    window.scrollTo(0, 0);
    Promise.resolve(this.props.offersFetched ? true : fetchJobOffers(this.props.actions)).then(
      () => {
        this.props.actions.selectOfferByIdentifier(offerIdentifier);
      }
    );
  }

  componentDidUpdate() {
    if (this.props.selectedOffer) {
      if (!this.state.isDownloadingRecruiterStarted) {
        this.setState({ isDownloadingRecruiterStarted: true });
        let recruiterDetails = fetchRecruiterDetails(
          this.props.selectedOffer.jobOfferPatron
            ? this.props.selectedOffer.jobOfferPatron.ID
            : null
        );
        recruiterDetails.then((response) => {
          this.setState({
            recruiterDetails: response,
          });
        });
      }

      if (
        this.props.selectedOffer.blogPost.url &&
        !this.props.selectedOffer.blogPost.detailsFetched
      ) {
        const offerIdentifier = this.props.params.seoSlug;

        fetchBlogDetails(this.props.selectedOffer.blogPost.url).then((blogDetailsObj) => {
          this.props.actions.setOfferBlogObject(offerIdentifier, blogDetailsObj);
        });
      }

      if (this.props.selectedOffer.ga_conversion_id) {
        window.gtag("event", "conversion", this.props.selectedOffer.ga_conversion_id);
      }

      if (this.props.selectedOffer.blogPost.details.title && !this.blogTimeout) {
        this.blogTimeout = setTimeout(() => this.showPopup("Blog"), 7000);
      }

      if (this.props.selectedOffer.pdf && !this.pdfTimeout) {
        this.pdfTimeout = setTimeout(() => this.showPopup("Pdf"), 7000);
      }
    } else if (this.props.offersFetched && this.props.selectedOffer === undefined) {
      this.props.navigate("404");
    }
  }

  componentWillUnmount() {
    this.props.actions.hideCvPrompt();
    this.props.actions.resetCurrentOffer();
    this.blogTimeout && clearTimeout(this.blogTimeout);
    this.pdfTimeout && clearTimeout(this.pdfTimeout);
  }

  showPopup = (type) => {
    this.setState({
      [`show${type}Popup`]: true,
    });
  };

  hidePopup = (type) => {
    this.setState({
      [`show${type}Popup`]: false,
    });
  };

  render() {
    let metaTags;
    if (this.props.selectedOffer) {
      const description = `${this.props.selectedOffer.title}, ${CONSTANTS.OG_DESCRIPTION}`;
      metaTags = [
        { name: "description", id: "description", content: description },
        {
          property: "og:title",
          id: "og-title",
          content: `${this.props.selectedOffer.title} / ${this.props.selectedOffer.cities.join()}`,
        },
        {
          property: "og:description",
          id: "og-description",
          content: description,
        },
        {
          property: "twitter:title",
          id: "twitter-title",
          content: `${this.props.selectedOffer.title} / ${this.props.selectedOffer.cities.join()}`,
        },
        {
          property: "twitter:description",
          id: "twitter-description",
          content: description,
        },
      ];

      if (this.props.selectedOffer.ogImage.imgSource) {
        metaTags.push({
          property: "og:image",
          id: "og-image",
          content: this.props.selectedOffer.ogImage.imgSource,
        });
        metaTags.push({
          property: "twitter:image",
          id: "twitter-image",
          content: this.props.selectedOffer.ogImage,
        });
        metaTags.push({
          property: "twitter:image:alt",
          id: "twitter-image-alt",
          content: description,
        });
      }
    }

    return (
      <div className="wrapper" aria-hidden={this.props.cvModalOpen}>
        {this.props.selectedOffer && <Helmet meta={metaTags} />}
        {this.props.selectedOffer ? (
          <JobOfferRedesigned
            offer={this.props.selectedOffer}
            recruiterDetails={this.state.recruiterDetails}
            cvModalOpen={this.props.cvModalOpen}
            gaTracking={{
              category: GA_EVENTS.offerCvButton.category,
              label: GA_EVENTS.offerCvButton.label,
            }}
            ga4Tracking={{
              eventName: GA4_EVENTS.offerCvButton.name,
            }}
          />
        ) : (
          <Spinner />
        )}
        {this.props.selectedOffer && <JobMicroformats offer={this.props.selectedOffer} />}
        {this.props.selectedOffer && (
          <Popups
            blogPopup={{
              show: this.state.showBlogPopup,
              data: this.props.selectedOffer.blogPost,
              hide: () => this.hidePopup("Blog"),
            }}
            pdfPopup={{
              show: this.state.showPdfPopup,
              data: this.props.selectedOffer.pdf,
              hide: () => this.hidePopup("Pdf"),
            }}
            cvModalOpen={this.props.cvModalOpen}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    selectedOffer: state.jobOffers.selectedOffer,
    offersFetched: state.jobOffers.offersFetched,
    // cvModalOpen: state.cvModal.cvModalOpen
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withURLParams(withNavigate(withURLParams(memo(Offer)))));
