export const JOB_OFFERS_SELECT_CITIES = "JOB_OFFERS_SELECT_CITIES";
export const JOB_OFFERS_SELECT_TECHNOLOGIES = "JOB_OFFERS_SELECT_TECHNOLOGIES";
export const JOB_OFFERS_SELECT_CITIES_AND_TECHNOLOGIES =
  "JOB_OFFERS_SELECT_CITIES_AND_TECHNOLOGIES";
export const JOB_OFFERS_ADD_TECHNOLOGY = "JOB_OFFERS_ADD_TECHNOLOGY";
export const JOB_OFFERS_REMOVE_TECHNOLOGY = "JOB_OFFERS_REMOVE_TECHNOLOGY";
export const JOB_OFFERS_ADD_CITY = "JOB_OFFERS_ADD_CITY";
export const JOB_OFFERS_REMOVE_CITY = "JOB_OFFERS_REMOVE_CITY";
export const JOB_OFFERS_SET_OFFERS = "JOB_OFFERS_SET_OFFERS";
export const SET_TECHNOLOGIES = "SET_TECHNOLOGIES";
export const SELECT_OFFER_BY_ID = "SELECT_OFFER_BY_ID";
export const SELECT_OFFER_BY_SLUG = "SELECT_OFFER_BY_SLUG";
export const SELECT_OFFER_BY_IDENTIFIER = "SELECT_OFFER_BY_IDENTIFIER";
export const SET_OFFER_BLOG_OBJECT = "SET_OFFER_BLOG_OBJECT";
export const RESET_CURRENT_OFFER = "RESET_CURRENT_OFFER";
export const SHOW_CV_PROMPT = "SHOW_CV_PROMPT";
export const HIDE_CV_PROMPT = "HIDE_CV_PROMPT";
export const IS_OFFERS = "IS_OFFERS";
export const IS_NOT_OFFERS = "IS_NOT_OFFERS";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";
export const OPEN_CV_MODAL = "OPEN_CV_MODAL";
export const CLOSE_CV_MODAL = "CLOSE_CV_MODAL";
export const SELECT_CATEGORY = "SELECT_CATEGORY";
export const SET_ERROR = "SET_ERROR";
export const RESET_ERROR = "RESET_ERROR";
export const CLOSE_COOKIE_BANNER = "CLOSE_COOKIE_BANNER";
