import photo_1 from "../assets/photo/carousel/photo_01.jpg";
import photo_2 from "../assets/photo/carousel/photo_02.jpg";
import photo_3 from "../assets/photo/carousel/photo_03.jpg";
import photo_4 from "../assets/photo/carousel/photo_04.jpg";
import photo_5 from "../assets/photo/carousel/photo_05.jpg";
import photo_6 from "../assets/photo/carousel/photo_06.jpg";
import photo_7 from "../assets/photo/carousel/photo_07.jpg";
import photo_8 from "../assets/photo/carousel/photo_08.jpg";
import photo_9 from "../assets/photo/carousel/photo_09.jpg";
import photo_11 from "../assets/photo/carousel/photo_11.jpg";
import photo_12 from "../assets/photo/carousel/photo_12.jpg";
import photo_13 from "../assets/photo/carousel/photo_13.jpg";
import photo_15 from "../assets/photo/carousel/photo_15.jpg";
import photo_17 from "../assets/photo/carousel/photo_17.jpg";
import photo_19 from "../assets/photo/carousel/photo_19.jpg";
import photo_20 from "../assets/photo/carousel/photo_20.jpg";
import photo_21 from "../assets/photo/carousel/photo_21.jpg";
import photo_25 from "../assets/photo/carousel/photo_25.jpg";
import photo_26 from "../assets/photo/carousel/photo_26.jpg";
import photo_27 from "../assets/photo/carousel/photo_27.jpg";
import photo_28 from "../assets/photo/carousel/photo_28.jpg";
import photo_29 from "../assets/photo/carousel/photo_29.jpg";
import photo_30 from "../assets/photo/carousel/photo_30.jpg";
import photo_31 from "../assets/photo/carousel/photo_31.jpg";
import photo_32 from "../assets/photo/carousel/photo_32.jpg";

const photoArr = [
  photo_1,
  photo_2,
  photo_3,
  photo_4,
  photo_5,
  photo_6,
  photo_7,
  photo_8,
  photo_9,
  photo_11,
  photo_12,
  photo_13,
  photo_15,
  photo_17,
  photo_19,
  photo_20,
  photo_21,
  photo_25,
  photo_26,
  photo_27,
  photo_28,
  photo_29,
  photo_30,
  photo_31,
  photo_32,
];

export default photoArr;
