import React from "react";
import { decode } from "blurhash";
import ProgressiveImage from "react-progressive-graceful-image";

const createImageBaseOnBlurHash = (hash) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  const pixels = decode(hash, 40, 20);
  const imageData = ctx.createImageData(40, 20);
  imageData.data.set(pixels);

  ctx.putImageData(imageData, 0, 0);
  ctx.scale(25, 25);
  ctx.drawImage(ctx.canvas, 0, 0);

  const imgBaseString = canvas.toDataURL("img/jpg");
  return imgBaseString;
};

export default function ImageWithBlur({ blurHash, imgSource, delay, ...props }) {
  return (
    <ProgressiveImage
      delay={delay}
      src={imgSource}
      placeholder={createImageBaseOnBlurHash(blurHash)}
    >
      {(src) => <img src={src} {...props} />}
    </ProgressiveImage>
  );
}
