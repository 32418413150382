import React, { Component, Fragment } from "react";

import ThankYou from "../components/ThankYouPage/ThankYou/ThankYou";
import * as ApiCalls from "./API";
import { parseFullName } from "../common/utils";
import DocumentTitle from "../components/common/DocumentTitle/DocumentTitle";
import { withURLParams, withNavigate } from "../components/helpers";

class ThankYouPage extends Component {
  constructor(props) {
    super(props);
    window.scrollTo(0, 0);
    this.state = {
      fullName: null,
    };
  }

  componentDidMount() {
    const { hash } = this.props.params;
    ApiCalls.refreshAgreement(hash).then((response) => {
      if (response.success) {
        const fullName = parseFullName(response.user);
        this.setState({
          fullName,
        });
      } else {
        this.handleError();
      }
    });
  }

  handleError() {
    this.props.navigate("/invalid-hash", {
      state: {
        error: "Something went wrong. Please check if you are using correct link",
      },
    });
  }

  render() {
    return (
      <Fragment>
        <ThankYou fullname={this.state.fullName} />
        <DocumentTitle title="Career XEBIA" />
      </Fragment>
    );
  }
}

export default withNavigate(withURLParams(ThankYouPage));
