import * as ACTION_TYPES from "../constants/ActionTypes";

const initialState = {
  message: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_ERROR:
      return {
        message: action.errorMessage,
      };
    case ACTION_TYPES.RESET_ERROR:
      return {
        message: "",
      };
    default:
      return state;
  }
}
