import React, { Component } from "react";
import "./_privacyPolicy.scss";

class PrivacyPolicy extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="PrivacyPolicy">
        <div className="PrivacyPolicy-contentWrapper">
          <h1 className="PrivacyPolicy-heading">
            PRIVACY POLICY FOR PARTICIPANTS
            <br />
            IN THE RECRUITMENT PROCESS
          </h1>
          <div className="PrivacyPolicy-content">
            <p className="PrivacyPolicy-paragraph">
              {`Thank you for your interest in current job offers at Xebia. The security of Personal Data of people taking part in recruitment is our highest priority, so in this document, we want to provide you with reliable information on how we handle your Personal Data.`}
            </p>
            <p className="PrivacyPolicy-paragraph">
              {`Xebia makes every effort to ensure that the processes related to the processing of Personal Data are properly optimised and secured. Xebia takes care of the security of Personal Data protecting it from unauthorised access and ensures the implementation of the rights resulting from:`}
            </p>
            <ul className="PrivacyPolicy-list">
              <li>{`Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons with regard to the processing of Personal Data and on the free movement of such data, and repealing Directive 95/46/EC, (hereinafter referred to as the "GDPR"),`}</li>
              <li>{`Act of 18 July 2002 on the provision of electronic services (Journal of Laws No. 144, item 1204 as amended),`}</li>
              <li>{`Act of 10 May 2018 on the Protection of Personal Data (Journal of Laws of 2018 item 1000 as amended).`}</li>
            </ul>
            <p className="PrivacyPolicy-paragraph">
              {`Xebia regularly trains its personnel in the field of Personal Data protection and conducts periodic audits related to ensuring the security of Personal Data processing.`}
            </p>

            <h2 className="PrivacyPolicy-subheading">Who is the Personal Data administrator?</h2>
            <p className="PrivacyPolicy-paragraph">
              The administrator of Personal Data of persons participating in recruitment processes
              is Xebia Sp. z o.o. with its registered office in Wrocław, ul. Sucha 3, 50-086
              Wrocław, entered into the Register of Entrepreneurs of the National Court Register
              kept by the District Court for Wrocław-Fabryczna in Wrocław, VI Commercial Division of
              the National Court Register under KRS number: 0000978067, with share capital:
              37,168,600.00 PLN, NIP number: 8971719181, REGON: 020363023, referred to herein as the
              “Administrator” or “Xebia”.
            </p>

            <h2 className="PrivacyPolicy-subheading">How can I contact the Administrator?</h2>
            <p className="PrivacyPolicy-paragraph">
              In matters concerning the processing of Personal Data, please contact us in writing by
              sending correspondence to the Administrator’s registered address, i.e. Xebia sp. z
              o.o., ul. Sucha 3, 50-086 Wrocław, or by electronic communication directed to the
              following e-mail address:{" "}
              <a href="mailto:gdpr.pl@xebia.com" className="PrivacyPolicy-mail" tabIndex="0">
                &nbsp;gdpr.pl@xebia.com
              </a>
            </p>

            <h2 className="PrivacyPolicy-subheading">
              What is the legal basis for the processing of Personal Data?
            </h2>
            <p className="PrivacyPolicy-paragraph">
              The legal basis for the processing of Personal Data of persons participating in
              recruitment processes may be:
            </p>
            <ol className="PrivacyPolicy-list">
              <li>
                Article 221 of the Labour Code and Article 6(1)(c) of the GDPR, and in the case
                termination of recruitment by a decision on the employment of a given candidate,
                Article 6(1)(b) GDPR.
              </li>
              <li>
                Article 6(1)(a) of the GDPR, and in the event of termination of recruitment by a
                decision to establish cooperation with a given candidate, Article 6(1)(b) of the
                GDPR.
              </li>
              <li>Article 6(1)(f) of the GDPR (legitimate interest of the Administrator).</li>
            </ol>
            <p className="PrivacyPolicy-paragraph">
              Detailed information on the processing of Personal Data of persons participating in
              recruitment processes, including the purposes of processing, is specified in the
              information obligation, which constitutes Appendix 1 to this Policy.
            </p>

            <h2 className="PrivacyPolicy-subheading">What kind of Personal Data do we collect?</h2>
            <p className="PrivacyPolicy-paragraph">
              Following the principle of minimisation of processing, which is one of the most
              important principles resulting from the GDPR, we do not collect excessive Personal
              Data. The contact forms used in the recruitment process include only Personal Data the
              acquisition of which is permissible in the light of applicable law.
            </p>
            <p className="PrivacyPolicy-paragraph">
              If you decide to provide us with additional Personal Data in your application, you
              will do so voluntarily, which is tantamount to your consent to the processing of such
              data by Xebia. Please note that you can change your mind and withdraw your consent at
              any time.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              Processing of special categories of Personal Data.
            </h2>
            <p className="PrivacyPolicy-paragraph">
              As a Personal Data administrator, we respect your privacy and encourage you to provide
              us with only such Personal Data that is necessary to establish an employment
              relationship or cooperation in a civil law form. With the consent of the data subject,
              the Administrator may process Personal Data of special categories, e.g. health data,
              if the processing of such data is necessary to ensure the rights of the data subject.
              Personal Data of special categories are processed only on the basis of the consent of
              the data subject or on the basis of the condition resulting from Article 6(1)(c) of
              the GDPR, i.e. in a situation in which processing is necessary to fulfil a legal
              obligation incumbent on the Administrator.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              To whom does the Administrator transfer Personal Data?
            </h2>
            <p className="PrivacyPolicy-paragraph">
              As a rule, Xebia does not transfer your Personal Data collected during the recruitment
              process to other entities. However, if it is decided to hire you or establish
              cooperation in a civil law form, it will be necessary to transfer your Personal Data
              to other entities such as state authorities (ZUS, Tax Office, PFRON (State Fund for
              Rehabilitation of Disabled Persons), etc.), as well as to our business partners who
              provide services to us on a daily basis. In some cases, your Personal Data may be
              transferred to our business partners or another company from the Xebia group, e.g. in
              connection with employment in a team that cooperates with entities located outside of
              Poland.
            </p>
            <p className="PrivacyPolicy-paragraph">
              Xebia makes every effort to ensure that the business entities cooperating with Xebia
              ensure high standards in the protection and processing of Personal Data, as well as
              implement the rights and obligations resulting from generally applicable law,
              including in particular the GDPR regulation.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              Transfer of Personal Data to third countries.
            </h2>
            <p className="PrivacyPolicy-paragraph">
              The Administrator intends to transfer Personal Data to recipients located outside the
              European Economic Area, e.g. to the United Kingdom. In the case of transfer of
              Personal Data to third countries, i.e. to recipients located outside the European
              Economic Area or in countries which, according to the European Commission, do not
              provide sufficient data protection, the Administrator transfers Personal Data using
              mechanisms consistent with applicable law, which include, among others:
            </p>
            <ol className="PrivacyPolicy-list">
              <li>EU “Standard Contractual Clauses”,</li>
              <li>
                Obtaining from the third party a certificate of compliance with the Privacy Shield
                (if they are established in the United States).
              </li>
            </ol>

            <h2 className="PrivacyPolicy-subheading">How long do we keep Personal Data?</h2>
            <p className="PrivacyPolicy-paragraph">
              The Administrator makes every effort to ensure that Personal Data are processed in an
              adequate manner and as long as it is necessary for the purposes for which they were
              collected. Bearing this in mind, the Administrator shall store Personal Data for a
              period not longer than necessary to achieve the purposes for which the data was
              collected or, if necessary, in order to comply with applicable law, in particular the
              period of contract implementation and the limitation period for claims.
            </p>

            <h2 className="PrivacyPolicy-subheading">
              What rights do you have in relation to the processing of Personal Data?
            </h2>
            <p className="PrivacyPolicy-paragraph">Each person has the right to:</p>
            <ul className="PrivacyPolicy-list">
              <li>access the content of their Personal Data and receive a copy of their data,</li>
              <li>rectification (correction) of Personal Data,</li>
              <li>deletion of Personal Data,</li>
              <li>restrictions on the processing of Personal Data,</li>
              <li>
                transfer of Personal Data, if the data are processed on the basis of a contract or
                consent,
              </li>
              <li>object to the processing of Personal Data,</li>
              <li>withdraw consent at any time,</li>
              <li>
                submit a complaint to the President of the Office for Personal Data Protection.
              </li>
            </ul>

            <h2 className="PrivacyPolicy-subheading">Automated decision-making.</h2>
            <p className="PrivacyPolicy-paragraph">
              Personal Data may be processed in an automated manner. In order to improve the
              recruitment process, the Administrator may use so-called “profiling”.
            </p>
            <p className="PrivacyPolicy-paragraph">
              Profiling should be understood as any form of automated processing of Personal Data
              that consists in the use of Personal Data to evaluate certain personal factors of a
              natural person, in particular to analyse or forecast aspects relating to the
              performance of that natural person’s work, economic situation, health, personal
              preferences, interests, reliability, behaviour, location or movement.
            </p>
            <p className="PrivacyPolicy-paragraph">
              It should be emphasised that the Administrator does not make automated decisions that
              have legal effects on the data subject.
            </p>

            <h2 className="PrivacyPolicy-subheading" id="PrivacyPolicy-cookies">
              Cookies Policy
            </h2>
            <ul className="PrivacyPolicy-list PrivacyPolicy-list--first">
              <li>
                The Website does not automatically collect any information about you, except for
                information contained in cookies.
              </li>
              <li>
                {`Cookie files ("cookies") are IT data units, in particular, text files which are
                stored on the Website’s end user’s device, and are intended to be used on the
                Website’s pages. Cookies usually contain the name of the website from which they
                originate, their storage time on the end user’s device, and a unique number.`}
              </li>
              <li>
                {`The entity which places cookies on the end user's device and gains access to them is
                Xebia sp. z o.o.`}
              </li>
              <li>
                {`Based on cookies, our marketing automation system observes your behavior on the
                Website, such as, for example, which of the Website’s pages you visited, how long
                the visit lasted, what kind of content you downloaded, and which forms you filled
                in.`}
              </li>
              <li>
                Until you enter your e-mail address, your activities on the Website are recorded
                anonymously, (we do not know who you are, but we know, for example, which country
                you are from, which type of browser you use, and which type of device you use to
                browse the Website).
              </li>
              <li>
                {`Cookies are used to adjust the content of the Website’s pages to the end-user's
                preferences, and to optimize the use of the Website; in particular, these files
                allow us to recognize the Website user's device, which consequently allows us to
                properly display the Website in a manner tailored to the end-user’s individual
                needs. They also allow us to create statistics, which help us understand how end
                users browse the Website, which allows us to improve its structure and content.`}
              </li>
              <li>
                {`The Website may use two basic types of cookies: "session" and "persistent" cookies.
                Session cookies are temporary files, which are stored on the user's end device until
                they log out, leave the Website, or turn off the software (web browser). Persistent
                cookies are stored in the user's end device for the time specified in the
                cookie-file parameters or until they are removed by the user.`}
              </li>
              <li>
                The following types of cookies may be used as part of the Website:
                <ul className="PrivacyPolicy-list PrivacyPolicy-list--inner">
                  <li>
                    {`"necessary" cookies, which facilitate the use of services available on the
                    Website, e.g. authentication cookies used for services which require
                    authentication on the Website,`}
                  </li>
                  <li>
                    cookies used to ensure security, e.g. used to detect fraud in the field of
                    authentication on the Website,
                  </li>
                  <li>
                    {`"performance" cookies, which enable the collection of information regarding the
                    use of the Website’s pages,`}
                  </li>
                  <li>
                    {`"functional" cookies, which allow settings selected by the end-user to be
                    “remembered” and for the end-user’s interface to be personalized, e.g. the
                    end-user’s selected language, region, font size, website appearance, etc.,`}
                  </li>
                  <li>
                    {`In many cases, software used for browsing websites (web browser) allows cookies
                    to be stored in the end-user's device by default. Website users may change their
                    cookie settings at any time. These settings can be changed in the web browser’s
                    settings in such a way as to block the automatic handling of cookies, or to
                    inform the end users about each time the cookies are being activated on the
                    Website user’s device. Detailed information regarding how cookies can be handled
                    is available in the software (web browser) settings,`}
                  </li>
                  <li>
                    The Website operator states that restrictions on the use of cookies can affect
                    some of the functionalities available on the Website,
                  </li>
                  <li>
                    {`Cookies placed on the Website user's device may also be used by advertisers and
                    partners cooperating with the Website operator,`}
                  </li>
                  <li>
                    {`More information about cookies can be found in the "Settings" or "Help" section
                    of your browser's menu,`}
                  </li>
                </ul>
              </li>
            </ul>

            <h2 className="PrivacyPolicy-subheading">Trademarks & Copyrights</h2>
            <ul className="PrivacyPolicy-list PrivacyPolicy-list--first">
              <li>
                Unless otherwise stated, the copyrights to pages, information, materials, and their
                deployment within the Website, belong to Xebia sp. z o.o., or have been transferred
                with the appropriate license to Xebia sp. z o.o.
              </li>
              <li>
                The Website also contains trademarks, trade names, and logos reserved both by Xebia
                sp. z o.o., and by companies and third parties. The trademarks, trade names, and
                logos have been used only to identify them. By making the trademarks, trade names,
                and logos available on the Website, Xebia sp. z o.o. does not grant a license to use
                license to use them in any way.
              </li>
              <li>
                Users may print, duplicate, copy, or temporarily store, parts of the Website for
                their own purposes, or in connection with the use of Xebia sp. z o.o. products and
                services.
              </li>
              <li>{`Users are not allowed to make any changes to the Website’s content.`}</li>
              <li>
                The detailed scope of the permitted and unlawful use of Website content is regulated
                by law, in particular, the Act of 4 February 1994 on copyright and related rights
                (Journal of Laws of 1994, No. 24, item 83, as amended). Violation of these rights
                can result in civil and/or criminal sanctions.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

export default PrivacyPolicy;
