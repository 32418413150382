import * as ACTION_TYPES from "../constants/ActionTypes";

const initialState = {
  cvModalOpen: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.OPEN_CV_MODAL:
      return {
        ...state,
        cvModalOpen: true,
      };
    case ACTION_TYPES.CLOSE_CV_MODAL:
      return {
        ...state,
        cvModalOpen: false,
      };
    default:
      return state;
  }
}
