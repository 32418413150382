import React, { PureComponent } from "react";
import { Link } from "react-router-dom";

import { getOfferLink } from "../../../../common/utils";
import "./_job-offers-list.scss";
import JobOffersCities from "./JobOffersCities/JobOffersCities";

class JobOffersList extends PureComponent {
  offerClassName(technology) {
    let offerClass = "JobsOffersList-item";
    offerClass += technology === "Staże" || technology === "Praktyki" ? " isIntern" : "";
    return offerClass;
  }

  render() {
    return (
      <div className="JobsOffersListWrapper">
        <ul className="JobsOffersList" aria-label="List of available jobs">
          {this.props.offers.map((offer, offerIndex) => (
            <li className={this.offerClassName(offer.technology)} key={offerIndex}>
              <Link
                className="JobOfferLink"
                to={getOfferLink(offer, this.props.technologiesNormalized)}
                tabIndex={this.props.cvModalOpen ? "-1" : "0"}
              >
                <span className="JobOfferLink-title">
                  {offer.technology === "Staże" && (
                    <span className="JobsOffersList-itemName--orange">Staż: </span>
                  )}
                  {offer.title}
                </span>
                <span className="JobOfferLink-technology" aria-hidden={true}>
                  {offer.technology}
                </span>
                <span className="JobOfferLink-salary" aria-hidden={true}>
                  <span>
                    {offer.salaryB2B ? "B2B: " : null}
                    {offer.salaryB2B}
                    {offer.salaryB2B ? " net + VAT" : null}
                  </span>
                  <span>
                    {offer.salaryEmploymentContract ? "Salary: " : null}
                    {offer.salaryEmploymentContract}
                    {offer.salaryEmploymentContract ? " gross" : null}
                  </span>
                </span>
                <JobOffersCities cities={offer.cities} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default JobOffersList;
