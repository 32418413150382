import React from "react";
import JobOffersMainFilter from "../JobOffersMainFilter/JobOffersMainFilter";

import "./_filters-modal.scss";

function FiltersModal({
  showFiltersOnMobile,
  onSelect,
  technologiesFromOffers,
  selectedCities,
  selectedTechnologies,
  citiesForFilter,
}) {
  return (
    <div className="FiltersModal">
      <div className="FiltersModal-content">
        <div className="FiltersModal-header">
          <span className="FiltersModal-title">Filters</span>
          <button onClick={showFiltersOnMobile} className="FiltersModal-closeBtn" />
        </div>
        <JobOffersMainFilter
          field="technology"
          values={technologiesFromOffers}
          onSelect={onSelect}
          initialValue={selectedTechnologies}
        />
        <JobOffersMainFilter
          field="city"
          values={citiesForFilter}
          onSelect={onSelect}
          initialValue={selectedCities}
        />
        <button onClick={showFiltersOnMobile} className="FiltersModal-filterBtn">
          filter
        </button>
      </div>
    </div>
  );
}

export default FiltersModal;
