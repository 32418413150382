import React from "react";
import "./AboutUsContent/_about-us-content.scss";
import "./_information.scss";

const Information = ({ title, content, type, isAboutCompany }) => (
  <section className="ParagraphText">
    <div className="ParagraphText-wrapper">
      <h2 className="ParagraphText-title">{title}</h2>
      <div
        className={`${isAboutCompany && "AboutUsContent"} ParagraphText-${type}`}
        dangerouslySetInnerHTML={{ __html: content }}
      ></div>
    </div>
  </section>
);

export default Information;
