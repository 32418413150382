import React, { Component, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import * as AppActions from "../actions/index";
import JobOffers from "../components/OffersPage/JobOffers/JobOffers";
import WeWaitForYou from "../components/OffersPage/WeWaitForYou/weWaitForYou";
import JobsListMicroformats from "../components/common/Microformats/JobsListMicroformats";
import DocumentTitle from "../components/common/DocumentTitle/DocumentTitle";
import { fetchJobOffers } from "./API";
import { GA_EVENTS } from "../constants/Tracking";

import { normalizeCity, normalizeTech, isCityParam, isTechnologyParam } from "./../common/utils";
import { withNavigate, withURLParams } from "../components/helpers/index";

const propTypes = {
  filteredOffers: PropTypes.any,
  offersFilters: PropTypes.any,
  technologiesFromOffers: PropTypes.any,
  technologiesNormalized: PropTypes.any,
  params: PropTypes.shape({
    cityOrTechnology: PropTypes.string,
    city: PropTypes.string,
    technology: PropTypes.string,
  }),
  actions: PropTypes.shape({
    selectCitiesFilter: PropTypes.func,
    selectTechnologiesFilter: PropTypes.func,
  }),
  navigate: PropTypes.func,
};

class Offers extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (!this.props.offersFetched) {
      fetchJobOffers(this.props.actions);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.technologiesFromOffers !== nextProps.technologiesFromOffers) {
      const cityParams = [];
      const techParams = [];

      const { params } = this.props;
      if (params) {
        if (params.cityOrTechnology) {
          params.cityOrTechnology.split(",").forEach((param) => {
            if (isCityParam(param)) {
              cityParams.push(normalizeCity(param));
            } else {
              const nomralizedTech = normalizeTech(param, nextProps.technologiesNormalized);
              nomralizedTech && techParams.push(nomralizedTech);
            }
          });
          this.props.actions.selectCitiesFilter(cityParams);
          this.props.actions.selectTechnologiesFilter(techParams);
        }

        if (params.city) {
          if (isCityParam(params.city)) {
            params.city.split(",").forEach((param) => {
              cityParams.push(normalizeCity(param));
            });
            this.props.actions.selectCitiesFilter(cityParams);
          } else {
            this.props.navigate("/", { replace: true });
          }
        }

        if (params.technology) {
          if (isTechnologyParam(params.technology, nextProps.technologiesNormalized)) {
            params.technology.split(",").forEach((param) => {
              techParams.push(normalizeTech(param, nextProps.technologiesNormalized));
            });
            this.props.actions.selectTechnologiesFilter(techParams);
          } else {
            this.props.navigate("/", { replace: true });
          }
        }
      }
    }
  }

  render() {
    return (
      <Fragment>
        <JobOffers
          allOffers={this.props.allOffers}
          offers={this.props.filteredOffers}
          offersFilters={this.props.offersFilters}
          actions={this.props.actions}
          technologiesFromOffers={this.props.technologiesFromOffers}
          technologiesNormalized={this.props.technologiesNormalized}
          params={this.props.params}
          cvModalOpen={this.props.cvModalOpen}
          offersFetched={this.props.offersFetched}
        />
        <DocumentTitle title="Job Offers" />
        {this.props.allOffers.length > 0 ? (
          <WeWaitForYou
            cvModalOpen={this.props.cvModalOpen}
            gaTracking={{
              category: GA_EVENTS.offerCvButton.category,
              label: GA_EVENTS.offerCvButton.label,
            }}
          />
        ) : null}
        <JobsListMicroformats
          offers={this.props.allOffers}
          technologies={this.props.technologiesNormalized}
        />
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allOffers: state.jobOffers.offers,
    offersFilters: state.jobOffers.offersFilters,
    filteredOffers: state.jobOffers.filteredOffers,
    technologiesFromOffers: state.jobOffers.technologiesFromOffers,
    technologiesNormalized: state.jobOffers.technologiesNormalized,
    offersFetched: state.jobOffers.offersFetched,
    cvModalOpen: state.cvModal.cvModalOpen,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

Offers.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(withNavigate(withURLParams(Offers)));
