import React from "react";
import ConsentInput from "../ConsentInput/ConsentInput";
import PropTypes from "prop-types";
import "./_recruitment-task.scss";

const propTypes = {
  date: PropTypes.string.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  dateValidationMsg: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  recruitmentTask: PropTypes.string.isRequired,
};

const RecruitmentTask = ({
  date,
  handleDateChange,
  dateValidationMsg,
  minDate,
  recruitmentTask,
}) => (
  <section className="RecruitmentTask">
    <h3 className="RecruitmentTask-header">Recruitment task</h3>
    <div
      className="RecruitmentTask-textWrapper"
      dangerouslySetInnerHTML={{ __html: recruitmentTask }}
    />
    <ConsentInput
      label={{
        for: "date",
        text: "Date:",
      }}
      input={{
        type: "date",
        id: "date",
        name: "date",
        value: date,
        onChange: handleDateChange,
        minDate,
        required: true,
      }}
      validationMsg={dateValidationMsg}
    />
  </section>
);

RecruitmentTask.propTypes = propTypes;
export default RecruitmentTask;
