import React, { Component } from "react";
import PropTypes from "prop-types";
import "./_benefit.scss";

const propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    details: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
};

class Benefit extends Component {
  render() {
    const { content } = this.props;

    return (
      <div className="Benefit">
        <div className="Benefit-mainInfo">
          <img src={content.icon} className="Benefit-icon" alt="" />
          <h3 className="Benefit-title">{content.title}</h3>
        </div>
        <div className="Benefit-extraInfo">
          <ul>
            {content.details.map((detail, i) => {
              return <li key={i}>{detail}</li>;
            })}
          </ul>
        </div>
      </div>
    );
  }
}

Benefit.propTypes = propTypes;
export default Benefit;
