const maxLength = 30;
const patterns = {
  name: /^[a-zA-ZąĄćĆęĘłŁńŃóÓśŚźŹżŻ' *-/]+$/,
  mail: /^[_a-zA-Z0-9-]+((\.[_a-zA-Z0-9-]+)*|(\+[_a-zA-Z0-9-]+)*)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{1,4})$/,
};
const errorMessages = {
  requiredField: "This field is required",
  invalidName: "Incorrect format. Only letters and spaces are allowed.",
  invalidMail: "Incorrect format. Please provide valid email address e.g. contact@acme.com",
  tooManyCharacters: "Too many characters",
};
const validResponse = { msg: "", isValid: true };

const getInvalidResponse = (msg) => ({ msg, isValid: false });

const isMatchingPattern = (value, pattern) => pattern.test(value);

const isTooLong = (value) => value.length > maxLength;

const isEmpty = (value) => value.trim().length === 0;

export const handleNameValidation = (inputValue) => {
  if (isEmpty(inputValue)) {
    return getInvalidResponse(errorMessages.requiredField);
  }
  if (!isMatchingPattern(inputValue, patterns.name)) {
    return getInvalidResponse(errorMessages.invalidName);
  }
  if (isTooLong(inputValue)) {
    return getInvalidResponse(errorMessages.tooManyCharacters);
  }
  return validResponse;
};

export const handleEmailValidation = (inputValue) => {
  if (isEmpty(inputValue)) {
    return getInvalidResponse(errorMessages.requiredField);
  }
  if (!isMatchingPattern(inputValue, patterns.mail)) {
    return getInvalidResponse(errorMessages.invalidMail);
  }
  return validResponse;
};
