import React from "react";

import ActivateButton from "../../common/ActivateButton/ActivateButton";

const sendCVButton = (props) => {
  return (
    <ActivateButton
      ariaLabel={props.ariaLabel}
      action={props.buttonUrl ? () => window.open(props.buttonUrl) : null}
      additionalClass={props.classes}
      text="Apply"
      type={props.type}
      gaTracking={props.gaTracking}
      ga4Tracking={props.ga4Tracking}
    />
  );
};

export default sendCVButton;
