import React from "react";
import "./_send-cv-widget.scss";

import SendCVbutton from "../../SendCVButton/sendCvButton";
import linkedinImg from "../../../../assets/icons/linkedin.png";
import xebiaLogo from "../../../../assets/img/logo.svg";
import MediaQuery from "react-responsive";

function SendCVWidget({ offer, recruiter, gaTracking, ga4Tracking, recruiterDetails }) {
  return (
    <section>
      {recruiter && recruiterDetails ? (
        <MediaQuery maxDeviceWidth={1023.999}>
          <div className="SendCVWidget-recruiterBlock">
            <img
              src={recruiterDetails.recruiterImg}
              className="SendCVWidget-recruiterImg"
              alt={recruiter.post_title + " recruiter at Xebia"}
            />
            <div className="SendCVWidget-recruiterTextBlock">
              <span className="SendCVWidget-recruiterCtaText">Any questions?</span>
              <span className="SendCVWidget-recruiterName">{recruiter.post_title}</span>
            </div>
            <a
              href={recruiterDetails.recruiterLinkedin}
              className="SendCVWidget-recruiterLinkedLink"
            >
              <img
                src={linkedinImg}
                width={30}
                height={30}
                className="SendCVWidget-recruiterLinkedImg"
                alt={"Contact with " + recruiter.post_title + " via Linkedin"}
              />
            </a>
          </div>
        </MediaQuery>
      ) : null}

      <div className="SendCVWidget">
        <div className="SendCVWidget-logoContainer">
          <a href="https://xebia.com" className="SendCVWidget-logoLink">
            <img src={xebiaLogo} className="SendCVWidget-logo" alt="Xebia logo" />
          </a>
        </div>
        <div className="SendCVWidget-infoContainer">
          <div className="SendCVWidget-ctaBlock">
            <h4 className="SendCVWidget-header">
              Applying takes <b className="SendCVWidget-boldText">less than a minute.</b>
            </h4>
            <SendCVbutton
              type="link"
              ariaLabel="Apply your resume (opens in new window)"
              buttonUrl={offer.recruitee_form_link}
              classes="Btn-fill forOffers SendCV-button"
              gaTracking={gaTracking}
              ga4Tracking={ga4Tracking}
            />
          </div>
          <MediaQuery minDeviceWidth={1024}>
            {recruiter && recruiterDetails ? (
              <div className="SendCVWidget-recruiterBlock">
                <img
                  src={recruiterDetails.recruiterImg}
                  className="SendCVWidget-recruiterImg"
                  alt={recruiter.post_title + " recruiter at Xebia"}
                />
                <div className="SendCVWidget-recruiterTextBlock">
                  <span className="SendCVWidget-recruiterCtaText">Any questions?</span>
                  <span className="SendCVWidget-recruiterName">{recruiter.post_title}</span>
                </div>
                <a
                  href={recruiterDetails.recruiterLinkedin}
                  className="SendCVWidget-recruiterLinkedLink"
                >
                  <img
                    src={linkedinImg}
                    width={30}
                    height={30}
                    className="SendCVWidget-recruiterLinkedImg"
                    alt={"Contact with " + recruiter.post_title + " via Linkedin"}
                  />
                </a>
              </div>
            ) : null}
          </MediaQuery>
        </div>
      </div>
    </section>
  );
}

export default SendCVWidget;
