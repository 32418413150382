const setCookie = (cookieDate, cookieValue, expireInDays) => {
  let expiryDate = new Date();
  expiryDate.setTime(expiryDate.getTime() + expireInDays * 24 * 60 * 60 * 1000);
  let expires = "expires=" + expiryDate.toGMTString();
  document.cookie = cookieDate + "=" + cookieValue + ";" + expires + ";path=/";
};
const getAllCookies = () => {
  return document.cookie.split(";").map((cookie) => {
    let [name, ...value] = decodeURIComponent(cookie).split("=");
    name = name.replace(" ", "");
    value = value.join("=");
    return {
      name,
      value,
    };
  });
};
const getCookie = (cookieName) => {
  let allCookies = getAllCookies();
  return allCookies.filter((cookie) => cookie.name === cookieName)[0] || null;
};

export default {
  setCookie,
  getCookie,
};
