import React from "react";
import SendCVbutton from "../SendCVButton/sendCvButton";

import "./_we-wait-for-you.scss";

const weWaitForYou = (props) => {
  return (
    <section className="SendCV" aria-hidden={props.cvModalOpen}>
      <img
        className="SendCV-wave"
        aria-hidden={true}
        alt=""
        src="../../../assets/img/wave-lines.svg"
      />
      <div className="SendCV-wrapper">
        <h2 className="SendCV-ctaText">
          If there is currently no job offer that fits you, contact us anyway.
          <br />
          We are always looking for talented people to join us!
        </h2>
        <SendCVbutton
          ariaLabel="Apply your cv"
          classes="Btn-fill forOffers SendCV-button"
          gaTracking={props.gaTracking}
          cvModalOpen={props.cvModalOpen}
        />
      </div>
    </section>
  );
};

export default weWaitForYou;
