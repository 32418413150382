import React from "react";
import Breadcrumb from "../../common/Breadcrumb/Breadcrumb";
import ThankBox from "../ThankBox/ThankBox";
import ThankImage from "../ThankImage/ThankImage";
import ShowJobOffers from "../../common/ShowJobOffers/ShowJobOffers";

import "./_thankYou-page.scss";

const ThankYou = ({ fullname }) => (
  <div className="ThankYou ready">
    <Breadcrumb currentPageSlug="stay-with-us" />
    <div className="ThankYou-content">
      <ThankImage />
      <ThankBox fullname={fullname} />
      <ShowJobOffers
        firstImg={{
          src: "/assets/photo/pgs/21.png",
          offset: "ThreeImages--offset_340",
          alt: "XEBIA Team",
        }}
        secondImg={{
          src: "/assets/photo/pgs/20.png",
          offset: "ThreeImages--offset_390",
          alt: "XEBIA Team",
        }}
      />
    </div>
  </div>
);

export default ThankYou;
