import React, { PureComponent } from "react";

class SvgFooterIcon extends PureComponent {
  render() {
    return (
      <svg
        className="Footer-icon"
        xmlns="http://www.w3.org/2000/svg"
        width={this.props.width}
        height={this.props.height}
        fill={this.props.fill}
        viewBox="0 0 24 24"
        role="img"
        aria-describedby="desc"
      >
        <desc id="desc">with love</desc>
        <path
          id="heart-icon"
          d="M12 4.435c-1.989-5.399-12-4.597-12 3.568 0 4.068 3.06 9.481 12 14.997 8.94-5.516 12-10.929 12-14.997 0-8.118-10-8.999-12-3.568z"
        />
      </svg>
    );
  }
}

SvgFooterIcon.defaultProps = {
  width: "12",
  height: "12",
  fill: "#ff3535",
};

export default SvgFooterIcon;
