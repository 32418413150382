import React, { Component } from "react";

import "./_job-offers-search-bar.scss";

class JobOffersSearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input: "",
    };
  }

  handleChange(event) {
    const keyword = event.target.value.trimStart();

    this.setState({
      input: keyword,
    });

    this.props.searchForAnOffers(keyword);
  }

  render() {
    return (
      <div className="JobOffersSearchBar">
        <label className="JobOffersSearchBar-inputLabel" htmlFor="JobOffersSearchBar-input">
          Keywords
        </label>
        <div className="JobOffersSearchBar-inputWrapper">
          <input
            autoComplete="organization-title"
            type="text"
            id="JobOffersSearchBar-input"
            name="JobOffersSearchBar-input"
            className="JobOffersSearchBar-input"
            placeholder="e.g. Java, AWS"
            value={this.state.input}
            onChange={this.handleChange.bind(this)}
            tabIndex={this.props.cvModalOpen ? "-1" : "0"}
            aria-describedby="JobOffersSearchBar-keywordsInfo"
          />
          <span id="JobOffersSearchBar-keywordsInfo" className="JobOffersSearchBar-keywordsInfo">
            During typing, page results will be matching to your criteria
          </span>
        </div>
      </div>
    );
  }
}

export default JobOffersSearchBar;
