import React from "react";

import "./_no-results.scss";

function NoResults() {
  return (
    <section className="NoResults">
      <h2 className="NoResults-header">Sorry, no results.</h2>
    </section>
  );
}

export default NoResults;
