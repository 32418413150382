import React from "react";
import { Link } from "react-router-dom";

import "./_thank-box.scss";

const ThankBox = ({ fullname }) => (
  <section className="ThankBox is-centered Line Line--double">
    <h2 className="ThankBox-header">
      ...for being with us, <span>{fullname}</span>
    </h2>
    <p className="ThankBox-paragraph">
      You can find details about the processing of your data in our
      <Link to="/privacy-policy" className="CookieInfo-link" target="_blank">
        {" "}
        Privacy Policy
      </Link>
      .
    </p>
  </section>
);

export default ThankBox;
