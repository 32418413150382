import React, { memo } from "react";
import MediaQuery from "react-responsive";
import Slider from "react-slick";
import "./_slick-slider.scss";

class SlickSlider extends React.Component {
  componentDidMount() {
    this.props.photos.forEach((picture) => {
      const img = new Image();
      img.src = picture.fileName;
    });
  }

  render() {
    const settings = {
      arrows: !this.props.cvModalOpen,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 2.2,
      slidesToScroll: 3,
      className: "Slide",
      variableWidth: true,
      responsive: [
        {
          breakpoint: 1024.99,
          settings: {
            slidesToScroll: 1,
          },
        },
      ],
    };

    const settingsMobile = {
      arrows: false,
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1.2,
      slidesToScroll: 1,
      className: "Slide",
      variableWidth: true,
      adaptiveHeight: true,
    };

    return (
      <aside className="CarouselWithPictures">
        <MediaQuery minWidth={780}>
          <Slider {...settings}>
            {this.props.photos.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="Slide-img-wrapper"
                    style={{ backgroundImage: "url(" + item + ")" }}
                  >
                    <img alt="" className="Slide-img" src={item} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </MediaQuery>
        <MediaQuery minWidth={600} maxWidth={779}>
          <Slider {...settingsMobile}>
            {this.props.photos.map((item, index) => {
              return (
                <div key={index}>
                  <div
                    className="Slide-img-wrapper"
                    style={{ backgroundImage: "url(" + item + ")" }}
                  >
                    <img alt="" className="Slide-img" src={item} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </MediaQuery>
      </aside>
    );
  }
}

export default memo(SlickSlider);
