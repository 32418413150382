import React from "react";
import PropTypes from "prop-types";

import "./_three-images.scss";

const ThreeImages = (props) => (
  <div className="ThreeImages">
    <div className="ThreeImages-imgWrapper ThreeImages-imgWrapper--first">
      <img
        className={`ThreeImages-image ${props.firstImg.offset}`}
        src={props.firstImg.src}
        alt={props.firstImg.alt}
      />
    </div>
    <div className="ThreeImages-imgWrapper ThreeImages-imgWrapper--second">
      <img
        className={`ThreeImages-image ${props.secondImg.offset}`}
        src={props.secondImg.src}
        alt={props.secondImg.alt}
      />
    </div>
  </div>
);

const propTypes = {
  firstImg: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    offset: PropTypes.string,
  }),
  secondImg: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string,
    offset: PropTypes.string,
  }),
};

ThreeImages.propTypes = propTypes;

export default ThreeImages;
