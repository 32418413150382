import React from "react";
import "./_benefits.scss";
import benefits from "../../../../data/newBenefits.json";
import Benefit from "./Benefit/Benefit";

function Benefits() {
  return (
    <section className="Benefits">
      <h2 className="Benefits-title">Benefits</h2>
      <div className="Benefits-listOfBenefits">
        <div className="Benefits-column">
          {benefits
            .filter((benefit, index) => index % 2 == 0)
            .map((benefit, index) => (
              <Benefit key={index} content={benefit} />
            ))}
        </div>
        <div className="Benefits-column">
          {benefits
            .filter((benefit, index) => index % 2 !== 0)
            .map((benefit, index) => (
              <Benefit key={index} content={benefit} />
            ))}
        </div>
      </div>
    </section>
  );
}

export default Benefits;
