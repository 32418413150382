import React from "react";
import ConsentInput from "../ConsentInput/ConsentInput";
import PropTypes from "prop-types";
import "./_contact-details.scss";

const propTypes = {
  email: PropTypes.string.isRequired,
  handleEmailChange: PropTypes.func.isRequired,
  emailValidationMsg: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  handleNameChange: PropTypes.func.isRequired,
  nameValidationMsg: PropTypes.string.isRequired,
  surname: PropTypes.string.isRequired,
  handleSurnameChange: PropTypes.func.isRequired,
  surnameValidationMsg: PropTypes.string.isRequired,
};

const ContactDetails = ({
  email,
  handleEmailChange,
  emailValidationMsg,
  name,
  handleNameChange,
  nameValidationMsg,
  surname,
  handleSurnameChange,
  surnameValidationMsg,
}) => (
  <section className="ContactDetails">
    <div className="ContactDetails-nameAndSurnameWrapper">
      <ConsentInput
        label={{
          for: "first-name",
          text: "First name:",
        }}
        input={{
          autoComplete: "given-name",
          type: "text",
          id: "first-name",
          name: "first-name",
          value: name,
          onChange: handleNameChange,
          placeholder: "Name",
          required: true,
        }}
        validationMsg={nameValidationMsg}
      />
      <ConsentInput
        label={{
          for: "surname",
          text: "Surname:",
        }}
        input={{
          autoComplete: "family-name",
          type: "text",
          id: "surname",
          name: "surname",
          value: surname,
          onChange: handleSurnameChange,
          placeholder: "Surname",
          required: true,
        }}
        validationMsg={surnameValidationMsg}
      />
    </div>
    <ConsentInput
      label={{
        for: "email-adress",
        text: "E-mail address:",
      }}
      input={{
        autoComplete: "email",
        type: "text",
        id: "email-adress",
        name: "email-adress",
        value: email,
        onChange: handleEmailChange,
        placeholder: "E-mail",
        required: true,
      }}
      validationMsg={emailValidationMsg}
    />
  </section>
);

ContactDetails.propTypes = propTypes;
export default ContactDetails;
