import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { createTrackingElement } from "../../common/utils";
import envelopIcon from "../../assets/icons/envelop.svg";

import "./_send-cv-success.scss";

const SendCVSuccess = ({ newsletter, tracking, onButtonClick, cvModalOpen }) => (
  <div className="SendCVSuccess" aria-hidden={cvModalOpen}>
    <h1 className="SendCVSuccess-text SendCVSuccess-text--mid-wide">
      <strong>Thank you!</strong>
    </h1>
    <h2 className="SendCVSuccess-text SendCVSuccess-text--mid">
      We will contact you in <br />
      <strong>2 weeks.</strong>
    </h2>
    <img src={envelopIcon} className="SendCVSuccess-img" alt="" />
    <div className="SendCVSuccess-text-infoWrapper">
      <p>
        The administrator of your personal data is Xebia sp. z o.o. with its registered office in
        Wrocław, ul. Sucha 3. We will process the data included in your CV only for recruitment
        purposes. The legal basis for processing your data is Article 221(1) of the Polish Labour
        Code (Kodeks pracy). We will process your personal data only during this recruitment
        process.
      </p>
      <p>
        However, if you give us a separate consent, we will be able to also process your data during
        future recruitment, i.e. up to 24 months from the date of transferring this data.
      </p>
      <p>
        You have the right to access the content of your personal data and the right to rectify,
        delete, limit processing, the right to data portability, the right to object, the right to
        withdraw consent to its processing at any time without affecting the legality of processing,
        which was performed on the basis of consent before its withdrawal. In order to exercise the
        aforementioned rights, please send an e-mail with the selected request to:
      </p>
      <p>
        <a href="mailto:gdpr.pl@xebia.com" tabIndex={cvModalOpen ? "-1" : "0"}>
          gdpr.pl@xebia.com
        </a>
      </p>
      <p>
        You have the right to lodge a complaint with a supervisory authority. If you believe that we
        process your data unlawfully, you can lodge a complaint to the supervisory authority located
        at Stawki 2 Street in Warsaw.
      </p>
      <p>
        We can share your data only if you give us your consent to do so or with authorized
        entities, if necessary.
      </p>
      <p>Your data will not be subject to automated processing.</p>
      {newsletter && (
        <div className="SendCVSuccess-text-infoWrapper-newsletter">
          <p>
            Thank you for subscribing to our Newsletter. Thanks to this consent we will be able to
            send you information about current events at Xebia sp. z o.o. and other various
            activities, such as conferences, trade fairs, changes to our websites, etc.
          </p>
          <p>
            You have the right to withdraw your consent to the processing of your personal data at
            any time. Withdrawal of consent will not affect the lawfulness of processing that was
            carried out on the basis of your consent before its withdrawal.
          </p>
          <p>
            The administrator of your personal data (first and last name as well as email address)
            is Xebia sp. z o.o. with its registered office in Wrocław, ul. Sucha 3. We know how
            important your personal data is, so if you want to know how we protect them, please read
            our
            <Link to="/privacy-policy" target="_blank" tabIndex={cvModalOpen ? "-1" : "0"}>
              <span className="SendCVSuccess-privacyPolicy">Privacy Policy.</span>
            </Link>
          </p>
        </div>
      )}
    </div>
    <div className="SendCVSuccess-buttonWrapper">
      <Link
        className="SendCVSuccess-button"
        to="/"
        onClick={onButtonClick}
        tabIndex={cvModalOpen ? "-1" : "0"}
      >
        <span>Check our job offers</span>
      </Link>
    </div>
    {tracking.isActive &&
      tracking.offer.details.tracking.map((singleTrack, index) => (
        <div key={index} dangerouslySetInnerHTML={createTrackingElement(singleTrack)} />
      ))}
  </div>
);

PropTypes.propTypes = {
  newsletter: PropTypes.bool,
  tracking: PropTypes.shape({
    isActive: PropTypes.bool.isRequired,
    offer: PropTypes.object,
  }),
};

export default SendCVSuccess;
