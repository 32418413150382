import React from "react";
import ImageWithBlur from "../../../common/ImageWithBlur/ImageWithBlur";
import BackButton from "./BackButton/BackButton";
import "./_job-offer-redesigned-jumbotron.scss";

const closeOnESC = () => {
  window.addEventListener("keydown", (event) => {
    if (event.keyCode === 27 || event.which === 27 || event.key === "Escape") {
      removeHover();
    }
  });
};

const removeHover = () => {
  document.querySelectorAll(".hovered").forEach((el) => {
    el.classList.remove("hovered");
  });
};

const addHover = (e) => {
  e.target.closest(".Jumbotron-requiredSkillWrapper").classList.add("hovered");
};

const splitSkills = (skills) => {
  const splittedSkills = skills.split(", ");
  for (let i = 0; i < splittedSkills.length - 1; i++) {
    splittedSkills[i] += ",";
  }

  return splittedSkills.map((skill) => (
    <span className="Jumbotron-splittedSkillName" key={skill}>
      {skill}
    </span>
  ));
};

function Jumbotron({
  title,
  salaryB2B,
  salaryEmploymentContract,
  featureImg,
  requiredSkills,
  optionalSkills,
}) {
  const skillsLegend = [
    {
      title: "Beginner",
      text: " – you started working with this technology not long ago.",
    },
    {
      title: "Intermediate",
      text: " – you know quite a bit but are still learning.",
    },
    {
      title: "Solid",
      text: " – you know the technology reasonably well and can use it regularly without issues.",
    },
    {
      title: "Advanced",
      text: " – you can work comfortably with this technology.",
    },
    {
      title: "Expert",
      text: " – you can design a whole architecture and solve any problem in this technology.",
    },
  ];

  let requiredSkillsFirstColumn;
  let requiredSkillsSecondColumn;

  closeOnESC();

  if (requiredSkills.length >= 1) {
    requiredSkillsFirstColumn = requiredSkills
      .filter((skill, index) => index % 2 === 0)
      .map((skill) => (
        <div
          key={skill.name}
          className="Jumbotron-requiredSkillWrapper"
          onMouseEnter={addHover}
          onMouseLeave={removeHover}
        >
          <div className="Jumbotron-requiredSkill">
            <div className="Jumbotron-splittedSkillsWrapper">{splitSkills(skill.name)}</div>
            <div className={`Jumbotron-requiredSkillProgressBar level${skill.level}`} />
          </div>
          <div className="Jumbotron-requiredSkillLabel">
            <strong>{skillsLegend[skill.level - 1].title}</strong>
            {skillsLegend[skill.level - 1].text}
          </div>
        </div>
      ));

    requiredSkillsSecondColumn = requiredSkills
      .filter((skill, index) => index % 2 !== 0)
      .map((skill) => (
        <div
          key={skill.name}
          className="Jumbotron-requiredSkillWrapper"
          onMouseEnter={addHover}
          onMouseLeave={removeHover}
        >
          <div className="Jumbotron-requiredSkill">
            <div className="Jumbotron-splittedSkillsWrapper">{splitSkills(skill.name)}</div>
            <div className={`Jumbotron-requiredSkillProgressBar level${skill.level}`} />
          </div>
          <div className="Jumbotron-requiredSkillLabel">
            <strong>{skillsLegend[skill.level - 1].title}</strong>
            {skillsLegend[skill.level - 1].text}
          </div>
        </div>
      ));
  }

  return (
    <section className="Jumbotron">
      <div className="Jumbotron-imageWrapper">
        <BackButton />
        <ImageWithBlur
          blurHash={featureImg.blurHash}
          imgSource={featureImg.imgSource}
          alt=""
          className="Jumbotron-image"
        />

        <div className="Jumbotron-descriptionText">
          <h1 className="Jumbotron-title">{title}</h1>
          {salaryB2B ? <span className="Jumbotron-salary">B2B: {salaryB2B} net + VAT</span> : null}
          {salaryEmploymentContract ? (
            <span className="Jumbotron-salary">Salary: {salaryEmploymentContract} gross</span>
          ) : null}
        </div>
      </div>
      {(requiredSkills.length > 0 || optionalSkills.length > 0) && (
        <div className="Jumbotron-skills">
          {requiredSkills.length ? (
            <div className="Jumbotron-requiredSkills">
              <div className="Jumbotron-requiredSkillsFirstColumn">{requiredSkillsFirstColumn}</div>
              {requiredSkillsSecondColumn && (
                <div className="Jumbotron-requiredSkillsSecondColumn">
                  {requiredSkillsSecondColumn}
                </div>
              )}
            </div>
          ) : null}
          {optionalSkills.length ? (
            <div className="Jumbotron-optionalSkills">
              <span className="Jumbotron-optionalSkillLabel" key="label">
                Optional:{" "}
              </span>
              <div>
                {optionalSkills.map((skill) => (
                  <span key={skill.name} className="Jumbotron-optionalSkill">
                    {skill.name}
                  </span>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </section>
  );
}

export default Jumbotron;
