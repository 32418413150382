import React from "react";
import { connect } from "react-redux";
import { Helmet } from "react-helmet-async";
import { bindActionCreators } from "redux";

import Page from "../components/common/Page/Page";
import ModalUploader from "../components/common/ModalUploader/ModalUploader";
import AccessibilityHelper from "../components/common/Accessibility/AccessibilityHelper";

import "../style/app.scss";

import * as AppActions from "../actions/index";
import * as CONSTANTS from "../constants/Constants";
import menu from "../constants/Menus.json";
import { Outlet, useLocation } from "react-router";
import Footer from "../components/common/Footer/Footer";

function App(props) {
  const location = useLocation();
  const displayFooterContext = ["/", "/thank-you", "/privacy-policy"];
  const footerVisible = displayFooterContext.includes(location.pathname);

  return (
    <React.Fragment>
      <main>
        <Page
          menu={menu}
          selectedOffer={props.selectedOffer}
          cvPromptVisible={props.cvPromptVisible}
          actions={props.actions}
          cvModalOpen={props.cvModalOpen}
        >
          {!props.selectedOffer && (
            <Helmet
              meta={[
                { name: "description", id: "description", content: CONSTANTS.OG_DESCRIPTION },
                { property: "fb:app_id", id: "fb-app-id", content: "177674802844485" },
                { property: "og:type", id: "og-type", content: "article" },
                { property: "og:url", id: "og-url", content: `${window.location.href}` },
                { property: "og:title", id: "og-title", content: "Join Us | Xebia" },
                {
                  property: "og:description",
                  id: "og-description",
                  content: CONSTANTS.OG_DESCRIPTION,
                },
                {
                  property: "og:image",
                  id: "og-image",
                  content: `https://${window.location.hostname}/assets/img/default-feature-img.png`,
                },
                {
                  property: "og:image:secure_url",
                  id: "og-image-secure-url",
                  content: `https://${window.location.hostname}/assets/img/default-feature-img.png`,
                },
                { property: "og:image:width", id: "og-image-width", content: "1200" },
                { property: "og:image:height", id: "og-image-height", content: "630" },
                { property: "twitter:site", id: "twitter-site", content: "@pgssoftware" },
                { property: "twitter:creator", id: "twitter-creator", content: "@pgssoftware" },
                { property: "twitter:site:id", id: "twitter-site-id", content: "64361523" },
                { property: "twitter:card", id: "twitter-card", content: "summary_large_image" },
              ]}
            />
          )}

          <ModalUploader
            selectedOffer={props.selectedOffer}
            cvModalOpen={props.cvModalOpen}
            closeModal={props.actions.closeCvModal}
            offersFilters={props.offersFilters}
            selectedCategory={props.selectedCategory}
          />
          <Outlet />
          <AccessibilityHelper />
        </Page>
      </main>
      {footerVisible && <Footer cvModalOpen={props.cvModalOpen} />}
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    selectedOffer: state.jobOffers.selectedOffer,
    cvPromptVisible: state.navigation.cvPromptVisible,
    cvModalOpen: state.cvModal.cvModalOpen,
    offersFilters: state.jobOffers.offersFilters,
    selectedCategory: state.technologiesSection.selectedCategory,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(AppActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
