import { combineReducers } from "redux";
import jobOffers from "./jobOffers";
import technologiesSection from "./technologiesSection";
import navigation from "./navigation";
import cvModal from "./cvModal";
import error from "./error";
import cookieBanner from "./cookieBanner";

const rootReducer = combineReducers({
  technologiesSection,
  jobOffers,
  navigation,
  cvModal,
  error,
  cookieBanner,
});

export default rootReducer;
