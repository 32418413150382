import React from "react";
import { withNavigate } from "../../../helpers";
import { useNavigate } from "react-router";
import "./_back-button-footer.scss";

const BackButtonFooter = () => {
  const navigate = useNavigate();

  return (
    <button className="BackButtonFooter" onClick={() => navigate("/")}>
      <span aria-hidden={true}>&larr;</span> Back to list
    </button>
  );
};

export default withNavigate(BackButtonFooter);
