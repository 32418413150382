import React from "react";
import PropTypes from "prop-types";

import "./_consent-input.scss";

const ConsentInput = (props) => (
  <div className={`ConsentInput ${props.extraClass ? props.extraClass : ""}`}>
    <div className="ConsentInput-wrapper">
      <div className="ConsentInput-wrapper-labelTitle">
        {props.input.required && <span>* </span>}
        <label htmlFor={props.label.for} className="ConsentInput-label">
          {props.label.text}
        </label>
      </div>

      <input
        className={`ConsentInput-input ${props.input.value ? "hasValue" : ""}`}
        autoComplete={props.input.autoComplete}
        type={props.input.type}
        id={props.input.name}
        name={props.input.type}
        value={props.input.value}
        onChange={props.input.onChange}
        min={props.input.minDate}
        placeholder={props.input.placeholder}
        required={props.input.required}
      />
    </div>
    <span aria-live="polite" className="ConsentInput-invalidDataInfo">
      {props.validationMsg}
    </span>
  </div>
);

ConsentInput.propTypes = {
  extraClass: PropTypes.string,
  label: PropTypes.shape({
    for: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
  input: PropTypes.shape({
    autoComplete: PropTypes.string,
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    minDate: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
  }),
  validationMsg: PropTypes.string.isRequired,
};

export default ConsentInput;
