import React from "react";
import { JSONLD, Generic } from "react-structured-data";

import { MICROFORMATS } from "../../../constants/Constants";
import { getOfferLink } from "../../../common/utils";

const JobsListMicroformats = (props) => (
  <JSONLD>
    <Generic
      type="itemlist"
      jsonldtype="ItemList"
      schema={{
        name: MICROFORMATS.JOB_OFFERS.NAME,
        itemListElement: props.offers.map((offer, index) => ({
          "@type": "ListItem",
          position: index,
          name: offer.title,
          url: getOfferLink(offer, props.technologies),
        })),
      }}
    />
  </JSONLD>
);

export default JobsListMicroformats;
