import React, { Component } from "react";
import filters from "../../../../assets/img/filters.svg";

import "./_open-filters-on-mobile-button.scss";

class OpenFiltersOnMobileButton extends Component {
  render() {
    const { showFiltersOnMobile } = this.props;

    return (
      <button className="OpenFiltersOnMobile" onClick={showFiltersOnMobile}>
        <img src={filters} alt="Filters icon" className="OpenFiltersOnMobile-icon" />
        <span className="OpenFiltersOnMobile-text">Filters</span>
      </button>
    );
  }
}

export default OpenFiltersOnMobileButton;
