import { Component } from "react";

const handleFirstTab = (e) => {
  if (e.keyCode === 9 || e.which === 9) {
    document.body.classList.add("tabActive");

    window.removeEventListener("keydown", handleFirstTab);
    window.addEventListener("mousedown", handleMouseDownOnce);
  }
};

const handleMouseDownOnce = () => {
  document.body.classList.remove("tabActive");

  window.removeEventListener("mousedown", handleMouseDownOnce);
  window.addEventListener("keydown", handleFirstTab);
};

class AccessibilityHelper extends Component {
  componentDidMount() {
    window.addEventListener("keydown", handleFirstTab);
  }

  render() {
    return null;
  }
}

export default AccessibilityHelper;
