import React, { Component } from "react";

import SocialMediaSprite from "../../../assets/icons/social-media.svg";

class Icon extends Component {
  render() {
    return (
      <svg
        className={`icon icon-${this.props.brand}`}
        fill={this.props.color}
        width={this.props.width}
        height={this.props.height}
      >
        <use xlinkHref={`${SocialMediaSprite}#${this.props.brand}`} />
      </svg>
    );
  }
}

export default Icon;
