import React from "react";
import { JSONLD, Generic } from "react-structured-data";

import { MICROFORMATS } from "../../../constants/Constants";

const XebiaMicroformats = () => (
  <JSONLD>
    <Generic
      type="organization"
      jsonldtype="Organization"
      schema={{
        name: MICROFORMATS.XEBIA.NAME,
        email: MICROFORMATS.XEBIA.EMAIL,
        telephone: MICROFORMATS.XEBIA.TELEPHONE,
      }}
    >
      <Generic
        type="address"
        jsonldtype="PostalAddress"
        schema={{
          addressLocality: MICROFORMATS.XEBIA.ADDRESS_LOCALITY,
          postalCode: MICROFORMATS.XEBIA.POSTAL_CODE,
          streetAddress: MICROFORMATS.XEBIA.STREET_ADDRESS,
        }}
      />
    </Generic>
  </JSONLD>
);

export default XebiaMicroformats;
