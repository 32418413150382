import React from "react";

import "./_thank-image.scss";

const ThankImage = () => (
  <section className="ThankImage is-centered">
    <img className="ThankImage-image" src="/assets/img/thank_you.png" />
  </section>
);

export default ThankImage;
