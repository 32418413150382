import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./_breadcrumb.scss";

import menu from "../../../constants/Menus.json";

class Breadcrumb extends Component {
  render() {
    const currentPageName = menu.map((el) => {
      if (el.url.indexOf(this.props.currentPageSlug) > -1) {
        this.childPage = el.url;
        return el.name;
      }
    });

    return (
      <nav className="Breadcrumbs" aria-hidden={this.props.cvModalOpen}>
        {this.props.currentPageSlug &&
          (this.props.clickable ? (
            <Link
              className={`Breadcrumb ${!this.props.offer && "is-active"}`}
              to={this.props.currentPageSlug}
              tabIndex={this.props.cvModalOpen ? "-1" : "0"}
            >
              {currentPageName}
            </Link>
          ) : (
            <span className="Breadcrumb is-active">{currentPageName}</span>
          ))}
        {this.props.offer && <span className="Breadcrumb is-active">{this.props.offer.title}</span>}
      </nav>
    );
  }
}

export default Breadcrumb;
