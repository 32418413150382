import React from "react";
import "./_modal-body.scss";

export const ModalBody = (props) => {
  const firstButton = React.createRef();
  const secondButton = React.createRef();

  const closeOnESC = (event) => {
    if (event.keyCode === 27 || event.which === 27 || event.key === "Escape") {
      props.closeModalFn();
    }
  };

  const onTab = (event) => {
    const isTab = event.key === "Tab" || event.keyCode === 9 || event.which === 9;

    if (!isTab) {
      return;
    }

    if (event.target === secondButton.current) {
      event.preventDefault();
      document.querySelector(".GetToKnowUs-iframe").contentWindow.focus();

      return;
    }

    if (event.target === firstButton.current && event.shiftKey) {
      event.preventDefault();
      document.querySelector(".GetToKnowUs-iframe").contentWindow.focus();
    }
  };

  React.useEffect(() => {
    firstButton.current.focus();
    window.addEventListener("keydown", closeOnESC);
  }, []);

  return (
    <div className="CareerModal">
      <div className="CareerModal-body">
        <button
          title="Close"
          ref={firstButton}
          className="CareerModal-closeButton"
          onClick={props.closeModalFn}
          onKeyDown={onTab}
        />
        {props.children}
        <button
          title="Close"
          ref={secondButton}
          className="CareerModal-closeButton"
          onClick={props.closeModalFn}
          onKeyDown={onTab}
        />
      </div>
    </div>
  );
};
