import React, { Component } from "react";
import MediaQuery from "react-responsive";
import "./_back-button.scss";
import { withNavigate } from "../../../../helpers";

class BackButton extends Component {
  handleClick = () => this.props.navigate("/");

  render() {
    return (
      <button className="BackButton" onClick={this.handleClick}>
        <MediaQuery maxDeviceWidth={1023}>
          <span aria-hidden={true}>&larr;</span>
          <span className="BackButton-hide">Back to lis</span>
        </MediaQuery>
        <MediaQuery minDeviceWidth={1024}>
          <span aria-hidden={true}>&larr;</span> &nbsp; Back to list
        </MediaQuery>
      </button>
    );
  }
}

export default withNavigate(BackButton);
