import { decode } from "html-entities";
import defaultImg from "../assets/img/default-feature-img.png";
import { TRACKING } from "../constants/Tracking";
import { ApiConfig } from "../constants/ApiConfig";
import { BLOG_POST_BUTTONS, BLUR_500_X_500_B2B_MARKETING } from "../constants/Constants";

function buildImageWithBlur(imgSource, blurHash = BLUR_500_X_500_B2B_MARKETING) {
  return {
    blurHash,
    imgSource,
  };
}

export function hydrateJobOffers(data) {
  let rebuildJobOffers = [];
  data.forEach(function (value) {
    let technicalSkills = {
      required: [],
      optional: [],
    };
    let tracking = [];

    if (value.acf.technologiesRequried) {
      buildTechnicalSkillsObject(value.acf.technologiesRequried, technicalSkills);
    }
    if (value.acf.technologiesOptional) {
      buildTechnicalOptionalSkillsObject(value.acf.technologiesOptional, technicalSkills);
    }
    if (value.acf.linkedin_conversion_id) {
      buildTrackingObject("linkedin", value.acf.linkedin_conversion_id, tracking);
    }

    const featureImg =
      value._embedded !== undefined
        ? value._embedded["wp:featuredmedia"][0].source_url
        : `${window.location.protocol}//${window.location.host}/${defaultImg}`;

    let rebuildJobOffer = {
      language: value.acf.langVersion,
      title: decode(value.title.rendered),
      salaryB2B: value.acf.salary_b2b,
      salaryEmploymentContract: value.acf.salary_employment_contract,
      showDatePicker: value.acf.show_date_picker,
      technology: value.acf.technologyName,
      cities: value.acf.cities,
      id: value.id,
      seoSlug: value.slug,
      ogImage: buildImageWithBlur(featureImg, value.acf.blurHash),
      mainCarouselHeroImg: value.acf.slider_cover_image,
      niceToHave: value.acf.nice_to_have,
      slider: value.acf.slider,
      aboutUs: value.acf.about_us,
      aboutProject: value.acf.about_project,
      requirements: value.acf.requirements,
      recruitee_form_link: value.acf.recruitee_form_link,
      responsibilities: value.acf.responsibilities,
      recruitmentProcess: value.acf.recruitment_process,
      recruitmentTask: value.acf.recruitment_task,
      jobOfferPatron: value.acf.job_offer_patron,
      targetMail: value.acf.targetMail || "",
      date: getDate(value.date),
      hideBenefits: value.acf.hideBenefits || false,
      blogPost: {
        url: value.acf.blogPost || "",
        details: {},
        detailsFetched: false,
      },
      pdf: buildPdfObject(value),
      ga_conversion_id: buildGtagObject(value),
      details: {
        offerType: value.acf.offerType || "techniczne",
        introduction: value.acf.introduction,
        technicalSkills: {
          required: technicalSkills.required,
          optional: technicalSkills.optional,
        },
        basicInformation: value.content.rendered,
        whyWork: value.acf.whyUs,
        otherInformation: value.acf.others,
        whatWeExpect: value.acf.whatWeExpect || "",
        headers: {
          baseInfo: value.acf.baseInfoHeader || "Podstawowe informacje:",
          technologies: value.acf.technologiesRequriedHeader || "Technologie:",
          whyUs: value.acf.whyUsHeader || "Dlaczego warto z nami pracować:",
          others: value.acf.othersHeader || "Pozostałe:",
          optionalTechnologies: value.acf.technologiesOptionalSubheader || "Mile widziane:",
          requiredTechnologies: value.acf.technologiesRequriedSubheader || "Wymagane:",
        },
        tracking: tracking,
      },
    };
    rebuildJobOffers.push(rebuildJobOffer);
  });

  return rebuildJobOffers;
}

function buildTechnicalSkillsObject(technologiesRequried, technicalSkills) {
  let requiredSkills = technologiesRequried.split("<br />\r\n");

  for (let key in requiredSkills) {
    let skillArray = requiredSkills[key].split(";");
    if (skillArray.length > 1) {
      technicalSkills.required.push({
        name: skillArray[0],
        level: skillArray[1],
      });
    }
  }
}

function buildTechnicalOptionalSkillsObject(technologiesOptional, technicalSkills) {
  let optionalSkills = technologiesOptional.split(";");

  for (let key in optionalSkills) {
    if (optionalSkills[key].length > 1) {
      technicalSkills.optional.push({
        name: optionalSkills[key],
      });
    }
  }
}

function buildTrackingObject(provider, conversionId, tracking) {
  tracking.push({
    provider: provider,
    partnerId: TRACKING[provider].partnerId,
    conversionId: conversionId,
  });
}

function getDate(dateString) {
  return dateString.substr(0, 10);
}

export function hydrateTechnologies(data) {
  let rebuildTechnologies = [];
  data.forEach(function (value) {
    let rebuildTechnology = {
      tagId: value.id,
      name: value.acf.technologyName,
      seoSlug: value.slug,
      iconWhite: value.acf.icon_white.url,
      iconOrange: value.acf.icon_orange.url,
      vimeourl: value.acf.vimeourl,
      description: value.acf.shortDescription,
      description_all: value.content.rendered,
      tags: value.acf.subtechnologies.split(";"),
    };
    rebuildTechnologies.push(rebuildTechnology);
  });

  return rebuildTechnologies;
}

export function buildBlogApiUrl(url) {
  let splittedUrl = url
    .split("/")
    .filter((urlChunk) => urlChunk !== "")
    .reverse();

  let slug = splittedUrl[0];
  let lang = splittedUrl[2] === "pl" ? "pl" : "en";

  return {
    apiUrl: `${ApiConfig.WEBSITE_BACKEND_URL}/${lang}/wp-json/wp/v2/posts?_embed&slug=${slug}`,
    lang: lang,
  };
}

export function buildBlogDetailsObj(response, lang) {
  let blogDetailsObj;

  if (response[0]) {
    blogDetailsObj = {
      title: removeHtmlTags(response[0].title.rendered),
      imgSrc: response[0]._embedded["wp:featuredmedia"][0].media_details.sizes.medium.source_url,
      link: {
        text: BLOG_POST_BUTTONS[lang],
        url: response[0].link,
      },
    };
  }

  return blogDetailsObj ? blogDetailsObj : {};
}

function removeHtmlTags(string) {
  return string.replace(/<\/?[^>]+(>|$)/g, "");
}

function buildPdfObject(value) {
  let pdfObject;

  if (value.acf.pdf) {
    pdfObject = {
      text: value.acf.pdf_text,
      buttonText: value.acf.pdf_button_text,
      src: value.acf.pdf_source,
    };
  }

  return pdfObject ? pdfObject : null;
}

export function buildRecruiterDetailsObj(response) {
  let recruiterDetailsObj;

  if (response) {
    recruiterDetailsObj = {
      recruiterLinkedin: response.acf.recruiter_linkedin_link,
      recruiterImg: response._embedded["wp:featuredmedia"][0].source_url,
    };
  }

  return recruiterDetailsObj ? recruiterDetailsObj : {};
}

function buildGtagObject(value) {
  let gtagObject;

  if (value.acf.ga_conversion_id) {
    gtagObject = {
      send_to: value.acf.ga_conversion_id,
      value: 1.0,
      currency: "PLN",
    };
  }

  return gtagObject ? gtagObject : null;
}
