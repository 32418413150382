import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import "./_send-button.scss";

const propTypes = {
  action: PropTypes.func.isRequired,
  additionalClass: PropTypes.string,
  sendingText: PropTypes.string,
  text: PropTypes.string,
  disabled: PropTypes.bool,
};

class SendButton extends PureComponent {
  constructor() {
    super();
    this.state = { sending: false };
  }

  onSend = () => {
    if (!this.props.disabled) {
      this.setState({
        sending: true,
      });
      this.props.action();
    }
  };

  render() {
    const btnClass = classNames({
      SendButton: true,
      "is-sending": this.state.sending,
    });

    return (
      <button
        ref={this.props.forwardedRef}
        className={`${btnClass} ${this.props.additionalClass}`}
        onClick={this.onSend}
        disabled={this.state.sending || this.props.disabled}
      >
        <span className="SendButton-text">
          {this.state.sending ? this.props.sendingText : this.props.text}
        </span>
      </button>
    );
  }
}

SendButton.defaultProps = {
  additionalClass: "",
  text: "Apply",
  sendingText: "Apply",
};

SendButton.propTypes = propTypes;
export default SendButton;
