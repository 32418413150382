import React, { PureComponent } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import CookieInfo from "../CookieInfo/CookieInfo";
import "./_page.scss";

class Page extends PureComponent {
  constructor(props) {
    super(props);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
  }

  handleDrag(event) {
    event.preventDefault();
    let dataTransfer = event.dataTransfer || event.nativeEvent.dataTransfer;
    let fileDragged = dataTransfer.types.indexOf
      ? dataTransfer.types.indexOf("Files") !== -1
      : dataTransfer.types.contains("Files");
    // fix for IE and Edge
    let imageDragged = dataTransfer.types.indexOf
      ? dataTransfer.types.indexOf("text/plain") !== -1
      : dataTransfer.types.contains("text/plain");
    if (!this.props.cvModalOpen && fileDragged && !imageDragged) {
      this.props.actions.openCvModal();
    }
  }

  handleDragLeave(event) {
    event.preventDefault();
    let pointerX = event.clientX || event.offsetX || -1;
    let pointerY = event.clientY || event.offsetY || -1;

    if (pointerX < 0 || pointerY < 0) {
      this.props.actions.closeCvModal();
    }
  }

  render() {
    const pageClass = classNames({
      Page: true,
    });

    return (
      <div
        className={pageClass}
        onDrop={(e) => e.preventDefault()}
        onDragOver={this.handleDrag}
        onDragLeave={this.handleDragLeave}
      >
        {this.props.children}
        <CookieInfo />
      </div>
    );
  }
}

Page.propTypes = {
  menu: PropTypes.array.isRequired,
};

export default Page;
