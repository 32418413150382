import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

import Cookies from "../../../containers/cookies";
import ActionButton from "../../../components/common/ActionButton/ActionButton";
import * as AppActions from "../../../actions/index";

import "./_cookie-info.scss";

class CookieInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.acceptedCookiesCookieName = "xebiaCookiesConfirmed";
  }

  componentDidMount() {
    this.cookiesAccepted() && this.props.closeCookieBanner();
  }

  cookiesAccepted() {
    return Cookies.getCookie(this.acceptedCookiesCookieName) !== null;
  }

  acceptCookies() {
    Cookies.setCookie(this.acceptedCookiesCookieName, true, 31);
    this.props.closeCookieBanner();
  }

  render() {
    return (
      this.props.isVisible && (
        <div className="CookieInfo">
          <p className="CookieInfo-text">
            We use cookies on our website to provide you with the best possible service. More
            details on{" "}
            <Link to="/privacy-policy" className="CookieInfo-link" target="_blank">
              Privacy Policy
            </Link>
          </p>
          <ActionButton
            additionalClass="CookieInfo-close"
            action={this.acceptCookies.bind(this)}
            text="Close"
          />
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    isVisible: state.cookieBanner.isVisible,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    closeCookieBanner: () => dispatch(AppActions.closeCookieBanner()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CookieInfo);
