import React, { Fragment } from "react";
import { connect } from "react-redux";

import PrivacyPolicy from "../components/common/PrivacyPolicy/PrivacyPolicy";
import XebiaMicroformats from "../components/common/Microformats/XebiaMicroformats";
import DocumentTitle from "../components/common/DocumentTitle/DocumentTitle";

const PrivacyPolicyPage = (props) => {
  window.scrollTo(0, 0);

  return (
    <Fragment>
      <PrivacyPolicy cvModalOpen={props.cvModalOpen} />
      <DocumentTitle title="Privacy Policy" />
      <XebiaMicroformats />
    </Fragment>
  );
};

function mapStateToProps(state) {
  return {
    cvModalOpen: state.cvModal.cvModalOpen,
  };
}

export default connect(mapStateToProps, null)(PrivacyPolicyPage);
