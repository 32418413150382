import { includes, uniq, find, without, intersection } from "lodash";
import * as ACTION_TYPES from "../constants/ActionTypes";

// TODO initialState na podstawie url
const initialState = {
  offers: [],
  offersFilters: {
    cities: [],
    technologies: [],
  },
  technologiesFromOffers: [],
  filteredOffers: [],
  offersFetched: false,
  selectedOffer: null,
};
let newState = {};
let newOffers = [];

const normalizeTech = (tech) => tech.replace(/[^A-Za-z0-9]/g, "").toLowerCase();

export default function jobOffers(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.JOB_OFFERS_SELECT_CITIES:
      newState = {
        offersFilters: {
          cities: action.cities,
          technologies: state.offersFilters.technologies,
        },
        filteredOffers: filterOffers(state.offers, action.cities, state.offersFilters.technologies),
      };
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.JOB_OFFERS_SELECT_TECHNOLOGIES:
      newState = {
        offersFilters: {
          cities: state.offersFilters.cities,
          technologies: action.technologies,
        },
        filteredOffers: filterOffers(state.offers, state.offersFilters.cities, action.technologies),
      };
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.JOB_OFFERS_SELECT_CITIES_AND_TECHNOLOGIES:
      newState = {
        offersFilters: {
          cities: action.cities,
          technologies: action.technologies,
        },
        filteredOffers: filterOffers(state.offers, action.cities, action.technologies),
      };
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.JOB_OFFERS_ADD_TECHNOLOGY:
      newState = {
        offersFilters: {
          cities: state.offersFilters.cities,
          technologies: uniq([...state.offersFilters.technologies, action.technology]),
        },
      };
      newState.filteredOffers = filterOffers(
        state.offers,
        state.offersFilters.cities,
        newState.offersFilters.technologies
      );
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.JOB_OFFERS_REMOVE_TECHNOLOGY:
      newState = {
        offersFilters: {
          cities: state.offersFilters.cities,
          technologies: uniq(without(state.offersFilters.technologies, action.technology)),
        },
      };
      newState.filteredOffers = filterOffers(
        state.offers,
        state.offersFilters.cities,
        newState.offersFilters.technologies
      );
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.JOB_OFFERS_ADD_CITY:
      newState = {
        offersFilters: {
          cities: uniq([...state.offersFilters.cities, action.city]),
          technologies: state.offersFilters.technologies,
        },
      };
      newState.filteredOffers = filterOffers(
        state.offers,
        newState.offersFilters.cities,
        state.offersFilters.technologies
      );
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.JOB_OFFERS_REMOVE_CITY:
      newState = {
        offersFilters: {
          cities: uniq(without(state.offersFilters.cities, action.city)),
          technologies: state.offersFilters.technologies,
        },
      };
      newState.filteredOffers = filterOffers(
        state.offers,
        newState.offersFilters.cities,
        state.offersFilters.technologies
      );
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.JOB_OFFERS_SET_OFFERS:
      newState = {};
      newState.offers = action.offers;
      newState.offersFetched = true;
      newState.filteredOffers = filterOffers(
        newState.offers,
        state.offersFilters.cities,
        state.offersFilters.technologies
      );
      newState.technologiesFromOffers = uniq(newState.offers.map((offer) => offer.technology));
      newState.technologiesNormalized = {};
      newState.technologiesFromOffers.forEach((tech) => {
        newState.technologiesNormalized[tech] = normalizeTech(tech);
      });
      return {
        ...state,
        ...newState,
      };
    case ACTION_TYPES.SELECT_OFFER_BY_ID:
      return {
        ...state,
        selectedOffer: find(state.offers, { id: action.offerId }),
      };
    case ACTION_TYPES.SELECT_OFFER_BY_SLUG:
      return {
        ...state,
        selectedOffer: find(state.offers, { seoSlug: action.seoSlug }),
      };
    case ACTION_TYPES.SELECT_OFFER_BY_IDENTIFIER:
      return {
        ...state,
        selectedOffer: find(
          state.offers,
          (offer) =>
            offer.seoSlug === action.identifier || offer.id === parseInt(action.identifier, 10)
        ),
      };
    case ACTION_TYPES.SET_OFFER_BLOG_OBJECT: {
      newOffers = state.offers.map((offer) => ({ ...offer }));
      let offerToUpdate = find(
        newOffers,
        (offer) =>
          offer.seoSlug === action.identifier || offer.id === parseInt(action.identifier, 10)
      );

      offerToUpdate.blogPost.details = action.blogDetailsObj;
      offerToUpdate.blogPost.detailsFetched = true;

      return {
        ...state,
        offers: newOffers,
        selectedOffer: offerToUpdate,
      };
    }
    case ACTION_TYPES.RESET_CURRENT_OFFER:
      return {
        ...state,
        selectedOffer: null,
      };
    default:
      return state;
  }
}

function filterOffers(offers, cities, technologies) {
  return offers.filter(
    (offer) =>
      (intersection(cities, offer.cities).length || cities.length === 0) &&
      (includes(technologies, offer.technology) || technologies.length === 0)
  );
}
