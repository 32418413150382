import React from "react";
import "./_close-modal-button.scss";
import PropTypes from "prop-types";

const propTypes = {
  handleClick: PropTypes.func.isRequired,
  text: PropTypes.string,
};

const CloseModalButton = ({ text, handleClick, forwardedRef }) => (
  <button className="CloseModalButton" onClick={handleClick} ref={forwardedRef}>
    <span>{text}</span>
  </button>
);

CloseModalButton.defaultProps = {
  text: "cancel",
};

CloseModalButton.propTypes = propTypes;
export default CloseModalButton;
