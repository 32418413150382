import React, { PureComponent } from "react";
import "./_action-button.scss";

class ActionButton extends PureComponent {
  render() {
    return (
      <button
        className={`ActionButton ${this.props.additionalClass}`}
        type={this.props.type}
        name={this.props.name}
        onClick={this.props.action}
      >
        <span>{this.props.text}</span>
      </button>
    );
  }
}

ActionButton.defaultProps = {
  additionalClass: "",
  type: "",
  name: "",
};

export default ActionButton;
