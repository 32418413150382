import * as ACTION_TYPES from "../constants/ActionTypes";

export function selectTechnologiesFilter(technologies) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_SELECT_TECHNOLOGIES,
    technologies,
  };
}

export function selectCitiesFilter(cities) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_SELECT_CITIES,
    cities,
  };
}

export function selectCitiesAndTechnologiesFilter(cities, technologies) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_SELECT_CITIES_AND_TECHNOLOGIES,
    cities,
    technologies,
  };
}

export function addCityToFilter(city) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_ADD_CITY,
    city,
  };
}

export function removeCityFromFilter(city) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_REMOVE_CITY,
    city,
  };
}

export function addTechnologyToFilter(technology) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_ADD_TECHNOLOGY,
    technology,
  };
}

export function removeTechnologyFromFilter(technology) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_REMOVE_TECHNOLOGY,
    technology,
  };
}

export function selectCategory(selectedCategory) {
  return {
    type: ACTION_TYPES.SELECT_CATEGORY,
    selectedCategory,
  };
}

export function setJobOffers(offers) {
  return {
    type: ACTION_TYPES.JOB_OFFERS_SET_OFFERS,
    offers,
  };
}

export function setTechnologies(technologies) {
  return {
    type: ACTION_TYPES.SET_TECHNOLOGIES,
    technologies,
  };
}

export function selectOfferById(offerId) {
  return {
    type: ACTION_TYPES.SELECT_OFFER_BY_ID,
    offerId,
  };
}

export function selectOfferBySlug(seoSlug) {
  return {
    type: ACTION_TYPES.SELECT_OFFER_BY_SLUG,
    seoSlug,
  };
}

export function selectOfferByIdentifier(identifier) {
  return {
    type: ACTION_TYPES.SELECT_OFFER_BY_IDENTIFIER,
    identifier,
  };
}

export function setOfferBlogObject(identifier, blogDetailsObj) {
  return {
    type: ACTION_TYPES.SET_OFFER_BLOG_OBJECT,
    identifier,
    blogDetailsObj,
  };
}

export function resetCurrentOffer() {
  return {
    type: ACTION_TYPES.RESET_CURRENT_OFFER,
  };
}

export function showCvPrompt() {
  return {
    type: ACTION_TYPES.SHOW_CV_PROMPT,
  };
}

export function hideCvPrompt() {
  return {
    type: ACTION_TYPES.HIDE_CV_PROMPT,
  };
}

export function isOffers() {
  return {
    type: ACTION_TYPES.IS_OFFERS,
  };
}

export function isNotOffers() {
  return {
    type: ACTION_TYPES.IS_NOT_OFFERS,
  };
}

export function setActivePage(pageName) {
  return {
    type: ACTION_TYPES.SET_ACTIVE_PAGE,
    pageName: pageName,
  };
}

export function openCvModal() {
  return {
    type: ACTION_TYPES.OPEN_CV_MODAL,
  };
}

export function closeCvModal() {
  return {
    type: ACTION_TYPES.CLOSE_CV_MODAL,
  };
}

export function setErrorMessage(errorMessage) {
  return {
    type: ACTION_TYPES.SET_ERROR,
    errorMessage: errorMessage,
  };
}

export function resetErrorMessage() {
  return {
    type: ACTION_TYPES.RESET_ERROR,
  };
}

export function closeCookieBanner() {
  return {
    type: ACTION_TYPES.CLOSE_COOKIE_BANNER,
  };
}
