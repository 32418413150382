import React from "react";
import { useLocation, useNavigate, useParams } from "react-router";

export const withHistoryLocation = (Component) => {
  const ComponentWithHistoryLocation = (props) => {
    const location = useLocation();
    return <Component {...props} location={location} />;
  };

  return ComponentWithHistoryLocation;
};

export const withNavigate = (Component) => {
  const ComponentWithNavigate = (props) => {
    const navigate = useNavigate();
    return <Component {...props} navigate={navigate} />;
  };

  return ComponentWithNavigate;
};

export const withURLParams = (Component) => {
  const ComponentWithURLParams = (props) => {
    const params = useParams();
    return <Component {...props} params={params} />;
  };

  return ComponentWithURLParams;
};
