import React from "react";
import { createPortal } from "react-dom";

export function Modal(props) {
  const body = document.body;
  const modalRoot = document.getElementById("modal-root");
  const el = document.createElement("div");
  el.classList.add("CareerModalWrapper");

  React.useLayoutEffect(() => {
    body.classList.add("CareerModal-open");
    modalRoot.appendChild(el);
    return () => {
      body.classList.remove("CareerModal-open");
      modalRoot.removeChild(el);
    };
  }, []);

  return createPortal(props.children, el);
}
