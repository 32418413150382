import React from "react";
import { Link } from "react-router-dom";

import "./_pageNotFound.scss";

import gif_404 from "../../../assets/img/gif_404.gif";

const PageNotFound = (props) => (
  <div className="PageNotFound">
    <h2 className="PageNotFound-title">{props.title}</h2>
    <img src={gif_404} alt="Strona nie istnieje" className="PageNotFound-icon" />
    <div className="PageNotFound-NavigationLinks">
      <Link className="PageNotFound-offers" to="/">
        <span>{props.btnText}</span>
      </Link>
    </div>
  </div>
);

PageNotFound.defaultProps = {
  title: "Oo, ta oferta nie jest już aktualna",
  btnText: "Zobacz inne oferty",
};

export default PageNotFound;
